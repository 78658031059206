import { config } from "process";

// Enum to avoid string literals in code
export enum configKeys {
  Kerberos = "Kerberos",
  Name = "Name",

  C = "President",
  _0 = "Risk Manager",
  _00 = "Brotherhood Chair",
  _000 = "Literary Chair",
  _0000 = "Senate Advisor",
  Th = "Treasurer",
  Comm = "Steward",
  D = "House Manager",
  P = "Social Chair",
  VC = "Vice President",
  PC = "New Member Educator",
  Q = "Rush Chair",
  AQ = "Assistant Rush Chair",
  N = "Network Administrator",

  Hometown = "Hometown",
  Major = "Major",
  Interests = "Interests",
  Big = "Big",

  CurrentSemester = "Fall 2024",
}

// This determines which classes are shown on brothers page as well as the order they are shown in.
// The key name doesn't matter, just that the value string matches the key in the brothers object below.
export enum activeYears {
  _2024 = "2024",
  _2025 = "2025",
  _2026 = "2026",
  _2027 = "2027",
}

// Officers that will appear at the bottom of brothers page
export const officers: {
  [position_name in configKeys | string]: {
    [officer_contacts: string]: string | string[];
  };
} = {
  [configKeys.C]: {
    [configKeys.Name]: "Kai McClennen",
    [configKeys.Kerberos]: "kmcclenn",
  },
  [configKeys._0]: {
    [configKeys.Name]: "Thomas Stueber",
    [configKeys.Kerberos]: "tstueber",
  },
  [configKeys._00]: {
    [configKeys.Name]: "Luke Schenk",
    [configKeys.Kerberos]: "lukesch",
  },
  [configKeys._000]: {
    [configKeys.Name]: "Hilal Hussain",
    [configKeys.Kerberos]: "hilal_h",
  },
  [configKeys._0000]: {
    [configKeys.Name]: "Brennan Hoppa",
    [configKeys.Kerberos]: "bhoppa",
  },
  [configKeys.Th]: {
    [configKeys.Name]: "Andrew Kessler",
    [configKeys.Kerberos]: "andrewlk",
  },
  [configKeys.Comm]: {
    [configKeys.Name]: "Jamison O'Keefe",
    [configKeys.Kerberos]: "jjokeefe",
  },
  [configKeys.D]: {
    [configKeys.Name]: "Theo Chen",
    [configKeys.Kerberos]: "tchen22",
  },
  [configKeys.P]: {
    [configKeys.Name]: "Ethan Reich",
    [configKeys.Kerberos]: "ereich04",
  },
  [configKeys.VC]: {
    [configKeys.Name]: "Ben Ebanks",
    [configKeys.Kerberos]: "bebanks3",
  },
  [configKeys.PC]: {
    [configKeys.Name]: "Ethan Wynia",
    [configKeys.Kerberos]: "ethanw11",
  },
  [configKeys.Q]: {
    [configKeys.Name]: "Rom Fradkin",
    [configKeys.Kerberos]: "rfradkin",
  },
  [configKeys.AQ]: {
    [configKeys.Name]: "Felix Huang",
    [configKeys.Kerberos]: "fhuang25",
  },
  [configKeys.N]: {
    [configKeys.Name]: "Kai McClennen",
    [configKeys.Kerberos]: "kmcclenn",
  },
};

// Brothers info that appears on brothers page.
// Also included big, little information. Since brothers can have 0, 1, or 2+ bigs (not common but can happen) or littles
//      these strings should follow this pattern ([a-zA-Z\.IVX\s]+ '[\d]{2,4}( &)?)+
//      Ex. Ethan Wynia '25 & Michael Holcomb '24
// I recommend adding in reverse chronological order so you don't have to scroll to see recent classes
export const brothers: {
  [class_year in configKeys | string]: {
    [brother_name: string]: { [brother_attribute_key: string]: string };
  };
} = {
  "2027": {
    "Parker Pettit": {
      [configKeys.Hometown]: "Sandpoint, ID",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]: "Guitar, lifting, hiking, camping, running",
      [configKeys.Big]: "Thomas Stueber '2026",
    },
    "Armando Melendez": {
      [configKeys.Hometown]: "Sayreville, NJ",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]: "Varsity Football, Hockey, Cooking, Poker",
      [configKeys.Big]: "Isaiah Nathaniel '2026",
    },
    "Dilin Meloni": {
      [configKeys.Hometown]: "Needham, MA",
      [configKeys.Major]: "22, 16-Eng",
      [configKeys.Interests]: "Varsity soccer, Fusion, Woodworking, Photography and Film, Space",
      [configKeys.Big]: "Kai McClennen '2026",
    },
    "Gyeongwu Kim": {
      [configKeys.Hometown]: "Buffalo Grove, IL",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Varsity football, Asian American Association, Hiking, AI/ML",
      [configKeys.Big]: "Drew Geoly '2026",
    },
    "Mitchell Hyland": {
      [configKeys.Hometown]: "Cleveland, OH",
      [configKeys.Major]: "18C",
      [configKeys.Interests]: "Sloan Business Club, Machine Learning, Accordion",
      [configKeys.Big]: "Luke Schenk '2026",
    },
    "Garrett Dyson": {
      [configKeys.Hometown]: "South Orange, NJ",
      [configKeys.Major]: "15-3",
      [configKeys.Interests]: "Varsity soccer, wildlife photography, whole milk, poker",
      [configKeys.Big]: "AZ Krebs '2026",
    },
    "Ike Chukwulozie": {
      [configKeys.Hometown]: "New York, NY",
      [configKeys.Major]: "2A-Entrepreneurship",
      [configKeys.Interests]: "Weightlifting, Cycling, Golf, Travel",
      [configKeys.Big]: "Daniel Monaghan '2025",
    },
    "Pradesh Mainali": {
      [configKeys.Hometown]: "Reston, VA",
      [configKeys.Major]: "6-4, 18",
      [configKeys.Interests]: "Wrestling, Poker, AI, Hiking, Music",
      [configKeys.Big]: "Justin Lee '2026",
    },
    "Cameron Wright": {
      [configKeys.Hometown]: "South Orange, NJ",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]: "Working out, Wrestling, Robotics, Skiing",
      [configKeys.Big]: "Oscar Lindenthal '2026",
    },
    "Shaunak Joshi": {
      [configKeys.Hometown]: "Tampa, FL",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]: "Varsity Football, Piano, Entrepreneurship",
      [configKeys.Big]: "Hilal Hussain '2026",
    },
    "Aitor Arrese-Igor": {
      [configKeys.Hometown]: "Boca Raton, FL",
      [configKeys.Major]: "6-3, 18",
      [configKeys.Interests]: "Varsity Swimming, talking to fish",
      [configKeys.Big]: "Theo Chen '2026",
    },
    "Hernando Ardila": {
      [configKeys.Hometown]: "Miramar, FL",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]: "Varsity Soccer",
      [configKeys.Big]: "Ethan Reich '2026",
    },
    "Paolo Mangiafico": {
      [configKeys.Hometown]: "Farmington, CT",
      [configKeys.Major]: "9, 7",
      [configKeys.Interests]: "Varsity soccer, neurobiology, singing, hiking, watching football",
      [configKeys.Big]: "Jamison O'Keefe '2026",
    },
  },
  "2026": {
    "Theo Chen": {
      [configKeys.Hometown]: "Plano, TX",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Swimming, Volleyball, Rock Climbing, Art",
      [configKeys.Big]: "Michael Wong '2025",
    },
    "Thomas Stueber": {
      [configKeys.Hometown]: "West Islip, NY",
      [configKeys.Major]: "6-14, 15-2",
      [configKeys.Interests]:
        "Varsity Football, Finance, Buying and Selling Sports Cards, Surfing",
      [configKeys.Big]: "Ethan Wynia '2025",
    },
    "Ethan Reich": {
      [configKeys.Hometown]: "New York, NY",
      [configKeys.Major]: "5-7",
      [configKeys.Interests]: "Varsity Soccer, Biotech, Running",
      [configKeys.Big]: "Ben Ebanks '2025",
    },
    "AZ Krebs": {
      [configKeys.Hometown]: "Watertown, MA",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]: "Varsity Soccer, Boston Sports",
      [configKeys.Big]: "Jack Minor '2025",
    },
    "Drew Geoly": {
      [configKeys.Hometown]: "Greenwood, SC",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]:
        "Varsity Football, Music, Pancakes, Occasional Catan",
      [configKeys.Big]: "Felix Huang '2025",
    },
    "Chase Williams": {
      [configKeys.Hometown]: "Missoula, MT",
      [configKeys.Major]: "15-3, 14-2",
      [configKeys.Interests]: "Varsity Football, Chess, Basketball, Analytics",
      [configKeys.Big]: "Noah Bryce '2023",
    },
    "Raymond Wang": {
      [configKeys.Hometown]: "Weston, Mass",
      [configKeys.Major]: "18, 6-3",
      [configKeys.Interests]: "Varsity soccer, SBC, startups",
      [configKeys.Big]: "Yajvan Ravan '2025",
    },
    "Jamison O'Keefe": {
      [configKeys.Hometown]: "Stratham, NH",
      [configKeys.Major]: "6-4, 18",
      [configKeys.Interests]: "Varsity Soccer, Guitar/Piano, Woodworking, FIFA",
      [configKeys.Big]: "Shane Lovett '2025",
    },
    "Ethan Thornton": {
      [configKeys.Hometown]: "Boerne, TX",
      [configKeys.Major]: "16",
      [configKeys.Interests]: "Engineering, Football, Metalworking",
      [configKeys.Big]: "Daniel Monaghan '2025",
    },
    "Mahmoud Abdelmoneum": {
      [configKeys.Hometown]: "Portland, OR",
      [configKeys.Major]: "6-9, 7",
      [configKeys.Interests]: "Reading, Hiking, Oranges",
      [configKeys.Big]: "Brennan Hoppa '2025",
    },
    "Hilal Hussain": {
      [configKeys.Hometown]: "McLean, VA",
      [configKeys.Major]: "6-4",
      [configKeys.Interests]:
        "Varsity Football, Quant",
      [configKeys.Big]: "Bradley Bunch '2025",
    },
    "Luke Schenk": {
      [configKeys.Hometown]: "Westfield, IN",
      [configKeys.Major]: "6-14, 15-3",
      [configKeys.Interests]:
        "Poker, Varsity Football, Weightlifting, Music, Investing, Pick-up basketball",
      [configKeys.Big]: "Lukas Hanson-Puffer '2024",
    },
    "Rom Fradkin": {
      [configKeys.Hometown]: "Glencoe, IL",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Cooking, Walks",
      [configKeys.Big]: "Joseph Gross '2025",
    },
    "Cole Firlie": {
      [configKeys.Hometown]: "Sarasota, FL",
      [configKeys.Major]: "18C & 15-3",
      [configKeys.Interests]: "Swimming",
      [configKeys.Big]: "Dylan Beck '2025",
    },
    "Justin Lee": {
      [configKeys.Hometown]: "Chattanooga, TN",
      [configKeys.Major]: "4",
      [configKeys.Interests]:
        "Solid-State Chemistry, Shapes, Kai",
      [configKeys.Big]: "Wayne Nelms '2025",
    },
    "Oscar Lindenthal": {
      [configKeys.Hometown]: "Vero Beach, FL",
      [configKeys.Major]: "20",
      [configKeys.Interests]:
        "Wakeboarding, golfing, playing tennis, and going to the beach.",
      [configKeys.Big]: "Andrew Kessler Jr. '2025",
    },
    "Kai McClennen": {
      [configKeys.Hometown]: "Jackson, WY",
      [configKeys.Major]: "6-3, 18",
      [configKeys.Interests]:
        "Hiking, Backpacking, Skiing, Climbing, Surfing, Varsity Soccer",
      [configKeys.Big]: "Arun Kirk '2025",
    },
    "Isaiah Nathaniel": {
      [configKeys.Hometown]: "Scotch Plains, NJ",
      [configKeys.Major]: "6-2",
      [configKeys.Interests]: "Varsity Lacrosse, NSBE, StartLabs, Robotics",
      [configKeys.Big]: "Ethan Robertson '2025",
    },
    "Evan Liu": {
      [configKeys.Hometown]: "Highland Mills, NY",
      [configKeys.Major]: "6-3, 18",
      [configKeys.Interests]: "Varsity swimming, Chess, Table tennis, FIFA",
      [configKeys.Big]: "Zachary Zhang '2024",
    },
    "Thomas Wu": {
      [configKeys.Hometown]: "Austin, TX",
      [configKeys.Major]: "6-3, 15-2",
      [configKeys.Interests]: "Varsity Swim, Travel, Food, Sleep, Hiking, Snowboarding",
      [configKeys.Big]: "Nathan Guntvedt '2025",
    },
  },
  "2025": {
    "Arun Kirk": {
      [configKeys.Hometown]: "Chapel Hill, NC",
      [configKeys.Major]: "6-3 & 14-1",
      [configKeys.Interests]: "Varsity Soccer, Hiking, Cooking",
      [configKeys.Big]: "Andrew Jenkins '2024",
    },
    "Joseph Gross": {
      [configKeys.Hometown]: "Miami, FL",
      [configKeys.Major]: "6-2",
      [configKeys.Interests]:
        "Varsity Soccer, Entrepreneurship, Traveling, SCUBA Diving",
      [configKeys.Big]: "Nick Ciepley '2023",
    },
    "Nathan Guntvedt": {
      [configKeys.Hometown]: "Santa Clara, CA",
      [configKeys.Major]: "6-9",
      [configKeys.Interests]: "Varsity Soccer, VR/AR MIT",
      [configKeys.Big]: "Mark Chiriac '2024",
    },
    "Jack Minor": {
      [configKeys.Hometown]: "Boca Raton, FL",
      [configKeys.Major]: "15",
      [configKeys.Interests]:
        "Varsity Soccer, Reading, Energy, Guitar, Investments",
      [configKeys.Big]: "James Simon '2024",
    },
    "Daniel Monaghan": {
      [configKeys.Hometown]: "Northfield, MN",
      [configKeys.Major]: "16 & 15-3",
      [configKeys.Interests]:
        "Varsity Football, Space Exploration, Pick-up Basketball",
      [configKeys.Big]: "Michael Holcomb '2024",
    },
    "Michael Wong": {
      [configKeys.Hometown]: "Shanghai, China",
      [configKeys.Major]: "6-9",
      [configKeys.Interests]: "Varsity Swimming, AI/ML, Entrepreneruship, DJ",
      [configKeys.Big]: "Andrei Spiride '2024",
    },
    "Brennan Hoppa": {
      [configKeys.Hometown]: "Hampstead, NH",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]: "Varsity Lacrosse, Rocket Team, Movies, Juggling",
      [configKeys.Big]: "Christian Viteri '2023",
    },
    "Ethan Robertson": {
      [configKeys.Hometown]: "Wheatley Heights, NY",
      [configKeys.Major]: "6-14 & 15-3",
      [configKeys.Interests]: "Varsity Lacrosse, NSBE, NBA, NFL, Lifting",
      [configKeys.Big]: "Eric Bell '2023",
    },
    "Andrew Kessler Jr.": {
      [configKeys.Hometown]: "San Diego, CA",
      [configKeys.Major]: "18 & 6-3",
      [configKeys.Interests]:
        "Varsity Lacrosse, Weightlifting, Poker",
      [configKeys.Big]: "Jake Sonandres '2023",
    },
    "Ethan Wynia": {
      [configKeys.Hometown]: "Billings, MT",
      [configKeys.Major]: "2",
      [configKeys.Interests]: "Basketball, Snowboarding, Music",
      [configKeys.Big]: "Nic Minudri '2023",
    },
    "Dylan Beck": {
      [configKeys.Hometown]: "Yorktown, NY",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Working Out, Hiking",
      [configKeys.Big]: "Will Kupiec '2023",
    },
    "Ben Ebanks": {
      [configKeys.Hometown]: "New York City, NY",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Men's Varsity Tennis, MIT Consulting Group, AI/ML, Civic Sedan",
      [configKeys.Big]: "Alvin Li '2023",
    },
    "Wayne Nelms": {
      [configKeys.Hometown]: "Baltimore, MD",
      [configKeys.Major]: "18-C",
      [configKeys.Interests]: "Men's Varsity Tennis, Poker, Quant Trading",
      [configKeys.Big]: "Jeremy Smithline '2024",
    },
    "Felix Huang": {
      [configKeys.Hometown]: "Rochester, NY",
      [configKeys.Major]: "6-4, 18",
      [configKeys.Interests]:
        "Varsity Football, MIT SportsLab, Piano, Poker",
      [configKeys.Big]: "Loic Venon '2024",
    },
    "Yajvan Ravan": {
      [configKeys.Hometown]: "Westland, MI",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "2025 Class Council, LTI, Table Tennis, Hiking, Robotics, Nano Tech",
      [configKeys.Big]: "Brendan Wagner '2023",
    },
    "Bradley Bunch": {
      [configKeys.Hometown]: "Delton, MI",
      [configKeys.Major]: "6-14",
      [configKeys.Interests]: "Varsity Football, StartLabs, Sandbox",
      [configKeys.Big]: "George Bian '2024",
    },
    "Shane Lovett": {
      [configKeys.Hometown]: "Tabernacle, NJ",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]: "Varsity Tabernacling, Recreational Tabernacling",
      [configKeys.Big]: "Will Seiple '2023",
    },
  },

  "2024": {
    "Michael Holcomb": {
      [configKeys.Hometown]: "Oak Park, CA",
      [configKeys.Major]: "15-3",
      [configKeys.Interests]:
        "Varsity Football, Varsity Track, Consulting, Sloan Business Club",
      [configKeys.Big]: "Nic Minudri '2023",
    },
    "Andrei Spiride": {
      [configKeys.Hometown]: "Plano, TX",
      [configKeys.Major]: "6-2",
      [configKeys.Interests]:
        "Varsity Swimming, MIT Driverless, Sloan Business Club, Spikeball",
      [configKeys.Big]: "Trent Piercy '2023",
    },
    "Andrew Jenkins": {
      [configKeys.Hometown]: "Crystal Lake, IL",
      [configKeys.Major]: "6-9",
      [configKeys.Interests]:
        "Mountains, Backpacking, Philosophy, Rock Climbing",
      [configKeys.Big]: "Brady Sullivan '2023",
    },
    "Zachary Zhang": {
      [configKeys.Hometown]: "Seattle, WA",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Varsity Soccer, Asian American Association, Asian Dance Team, Chess, Cello, Poker",
      [configKeys.Big]: "Billy Jones '2022",
    },
    "George Bian": {
      [configKeys.Hometown]: "Saratoga, CA",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Class Council, Asian American Association, Cooking, Running",
      [configKeys.Big]: "Kris Vu '2023",
    },
    "Jeremy Smithline": {
      [configKeys.Hometown]: "Newtown, PA",
      [configKeys.Major]: "18 & 6-14",
      [configKeys.Interests]: "Running, Soccer, Poker",
      [configKeys.Big]: "Oakley Dehning '2023",
    },
    "Stephen Hong": {
      [configKeys.Hometown]: "Huntington Station, NY",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Badminton",
      [configKeys.Big]: "Kaleb Blake '2022",
    },
    "Mark Chiriac": {
      [configKeys.Hometown]: "Plainsboro, NJ",
      [configKeys.Major]: "18-C",
      [configKeys.Interests]: "Varsity Soccer, PBE Softball",
      [configKeys.Big]: "Andrei Dumitrescu '2022",
    },
    "James Simon": {
      [configKeys.Hometown]: "Ann Arbor, MI",
      [configKeys.Major]: "6-14 & 15-2",
      [configKeys.Interests]:
        "Varsity Soccer, Poker, Snorkeling, Chess, Piano, Cryptocurrency Trading",
      [configKeys.Big]: "Jack Oswald '2023",
    },
    "Loic Venon": {
      [configKeys.Hometown]: "Pewaukee, WI",
      [configKeys.Major]: "6-14 & 15-3",
      [configKeys.Interests]:
        "Poker, Varsity Football, Poker, Cooking, Running, Poker, Hiking, Boxing, Poker",
      [configKeys.Big]: "Nick Anewalt '2023",
    },
    // "Fritzgerald Duvigneaud": {
    //   [configKeys.Hometown]: "Wellington, FL",
    //   [configKeys.Major]: "16",
    //   [configKeys.Interests]: "Building Robots, Rollerblading",
    //   [configKeys.Big]: "",
    // },
    "Lukas Hanson-Puffer": {
      [configKeys.Hometown]: "London, UK",
      [configKeys.Major]: "14-1 & 15-1",
      [configKeys.Interests]:
        "Varsity Soccer, International Development, Cooking, Consulting",
      [configKeys.Big]: "Matt Jens '2023",
    },
    "Aidan Hallinan": {
      [configKeys.Hometown]: "San Diego, CA",
      [configKeys.Major]: "22",
      [configKeys.Interests]:
        "Varsity Soccer, Snowboarding, Reading, Working Out, Navy ROTC, Asking Questions ",
      [configKeys.Big]: "Matt Leonard '2023",
    },
  },

  "2023": {
    "Jack Oswald": {
      [configKeys.Hometown]: "Calabasas, CA",
      [configKeys.Major]: "16",
      [configKeys.Interests]:
        "Varsity Football, Cooking Rockets, Building Steak",
      [configKeys.Big]: "Connor Sweeney '2021",
    },
    "Matt Leonard": {
      [configKeys.Hometown]: "San Antionio, TX",
      [configKeys.Major]: "6-14 & 15-3",
      [configKeys.Interests]:
        "Varsity Baseball, Tech Investing, Growth Equity, Country Music",
      [configKeys.Big]: "Theo St. Francis '2022",
    },
    "Christian Viteri": {
      [configKeys.Hometown]: "Huntington, NY",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]:
        "Varsity Football, Mechanical Design, Robotics, Music, Film",
      [configKeys.Big]: "Justen Holl '2021"
    },
    "Noah Bryce": {
      [configKeys.Hometown]: "Austin, TX",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Varsity Football, Squats",
      [configKeys.Big]: "Hayden Rome '2022"
    },
    "Brady Sullivan": {
      [configKeys.Hometown]: "Littleton, CO",
      [configKeys.Major]: "6-2, 2-Minor",
      [configKeys.Interests]:
        "Varsity Football, Ring Comm Co-Delivery Chair, Hiking, Skiing, Bird Watching",
      [configKeys.Big]: "Nathan Basinger '2022"
    },
    "Trent Piercy": {
      [configKeys.Hometown]: "Austin, TX",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Varsity Swimming, VR/AR@MIT President, Co-Founder of Thrifthouse",
      [configKeys.Big]: "Jacob Phillips '2021"
    },
    "Kris Vu": {
      [configKeys.Hometown]: "Malden, MA",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]:
        "Amphibious Achievement, Camp Kesem, Asian American Association, Running",
      [configKeys.Big]: "Jaya Kambhampaty '2022"
    },
    "Jake Sonandres": {
      [configKeys.Hometown]: "Torrance, CA",
      [configKeys.Major]: "16 & 6",
      [configKeys.Interests]:
        "Varsity Baseball, Camp Kesem, Space Systems, Surfing, Lifting, Poker",
      [configKeys.Big]: "Cory O'Shea '2022"
    },
    "Scott Becker": {
      [configKeys.Hometown]: "McLean, VA",
      [configKeys.Major]: "6-3 & 18",
      [configKeys.Interests]: "Varsity Soccer, Mathematics, Game Theory, NBA",
      [configKeys.Big]: "Kevin Yue '2021"
    },
    "Brendan Wagner": {
      [configKeys.Hometown]: "Elmhurst, IL",
      [configKeys.Major]: "18C",
      [configKeys.Interests]: "Varsity Swimming, MIT Energy Initiative",
      [configKeys.Big]: "Alex Ellison '2022"
    },
    "Richter Brzeski": {
      [configKeys.Hometown]: "Los Angeles, CA",
      [configKeys.Major]: "6-2",
      [configKeys.Interests]: "Varsity Soccer, FIFA",
      [configKeys.Big]: "Garrett Gordon '2022"
    },
    "Eric Bell": {
      [configKeys.Hometown]: "Huntington, NY",
      [configKeys.Major]: "2",
      [configKeys.Interests]:
        "Varsity Football, Weightlifting, Hiking, Spikeball",
      [configKeys.Big]: "Kaleb Blake '2022"
    },
    "Nick Ciepley": {
      [configKeys.Hometown]: "San Diego, CA",
      [configKeys.Major]: "16",
      [configKeys.Interests]:
        "Varsity Soccer, Surfing, Skiing, Space Technology",
      [configKeys.Big]: "Sebastian Simon '2022"
    },
    "Will Kupiec": {
      [configKeys.Hometown]: "Manhattan Beach, CA",
      [configKeys.Major]: "16",
      [configKeys.Interests]: "Motorsport, Aviation, Skiing",
      [configKeys.Big]: "Nick West '2022"
    },
    "Matt Jens": {
      [configKeys.Hometown]: "Winchester, MA",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]:
        "Vice Chair of Ring Committee, Sloan Business Club, Entrepreneurship, Pencil Drawing, Beating Minudri at Fantasy Football",
        [configKeys.Big]: "Luke Stewart '2022"
    },
    "Nick Anewalt": {
      [configKeys.Hometown]: "Memphis, TN",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Soccer, Snowboarding, SCUBA Diving, Skydiving",
      [configKeys.Big]: "Jack Phifer '2022"
    },
    "Oakley Dehning": {
      [configKeys.Hometown]: "Longmont, CO",
      [configKeys.Major]: "6-14 & 15-3",
      [configKeys.Interests]: "Varsity Football, Real Estate Investing",
      [configKeys.Big]: "Emmanuel Ogunde '2021"
    },
    "Will Seiple": {
      [configKeys.Hometown]: "Boulder, CO",
      [configKeys.Major]: "18C",
      [configKeys.Interests]: "Varsity Soccer, Skateboarding",
      [configKeys.Big]: "Noah Faro '2022"
    },
    "Nic Minudri": {
      [configKeys.Hometown]: "Moscow, ID",
      [configKeys.Major]: "6-14 & 15-3",
      [configKeys.Interests]: "Varsity Track, Sloan Business Club",
      [configKeys.Big]: "Billy Jones '2022"
    },
    "Alvin Li": {
      [configKeys.Hometown]: "Palo Alto, CA",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Varsity Crew, Surfing, Snowboarding, Quant Finance, Entrepreneurship, Venture Capital, AI/ML",
      [configKeys.Big]: "Jaya Kambhampaty '2022"
    },
  },

  "2022": {
    "Jaya Kambhampaty": {
      [configKeys.Hometown]: "Arlington, VA",
      [configKeys.Major]: "16",
      [configKeys.Interests]: "Aerospace Engineering",
      [configKeys.Big]: "Alan Chen '2019"
    },
    "Andrei Dumitrescu": {
      [configKeys.Hometown]: "Andover, MA",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Varsity Soccer, Biotech, Alpine Skiing, House Music",
      [configKeys.Big]: "Ariel Brito '2020"
    },
    "Nathan Basinger": {
      [configKeys.Hometown]: "Dalton, OH",
      [configKeys.Major]: "2",
      [configKeys.Interests]:
        "Varsity Football Captain, Track & Field Captain, Archery",
      [configKeys.Big]: "Mac Gregg '2021"
    },
    "Billy Jones": {
      [configKeys.Hometown]: "Brookfield, MA",
      [configKeys.Major]: "6-14 & 21L",
      [configKeys.Interests]:
        "Varsity Football, Little Beavers, Consulting, Reading, PBE Softball Team",
      [configKeys.Big]: "Jacob Phillips '2021"
    },
    "Luke Stewart": {
      [configKeys.Hometown]: "New Canaan, CT",
      [configKeys.Major]: "8",
      [configKeys.Interests]: "Varsity Swimming, Physics",
      [configKeys.Big]: "AJ Iversen '2020"
    },
    "Noah Faro": {
      [configKeys.Hometown]: "Clifton Park, NY",
      [configKeys.Major]: "6-3 & 20",
      [configKeys.Interests]:
        "Varsity Soccer, Camp Kesem, Photography, Taekwondo",
        [configKeys.Big]: "Connor Sweeney '2021"
    },
    "Garrett Gordon": {
      [configKeys.Hometown]: "Houston, TX",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Varsity Soccer, Music Production, Basketball",
      [configKeys.Big]: "Emmanuel Ogunde '2021"
    },
    "Sebastian Simon": {
      [configKeys.Hometown]: "Detroit, MI",
      [configKeys.Major]: "15-3 & 14-2",
      [configKeys.Interests]:
        "Varsity Soccer, Camp Kesem, Music Production, Finance, Guitar",
      [configKeys.Big]: "Kunal Tangri '2019"
    },
    "Theo St. Francis": {
      [configKeys.Hometown]: "Sonoma, CA",
      [configKeys.Major]: "2",
      [configKeys.Interests]:
        "Rockets, Aviation (student pilot), Open-Water Swimming",
      [configKeys.Big]: "Vick Liu '2020"
    },
    "Kaleb Blake": {
      [configKeys.Hometown]: "Los Angeles, CA",
      [configKeys.Major]: "2A-6",
      [configKeys.Interests]: "Varsity Soccer, Reading, Chilling",
      [configKeys.Big]: "Dotun Adegbite '2019"
    },
    "Hayden Rome": {
      [configKeys.Hometown]: "Houston, TX",
      [configKeys.Major]: "18 & 6-3",
      [configKeys.Interests]: "Football, Soccer, Music",
      [configKeys.Big]: "Justen Holl '2021"
    },
    "Reggie Best": {
      [configKeys.Hometown]: "Odenton, MD",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]:
        "Varsity Football, Hip Hop, Theater Studies, MIT Sports Lab",
      [configKeys.Big]: "Jack Strachan '2020"
    },
    "Cory O'Shea": {
      [configKeys.Hometown]: "Glen Allen, VA",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Varsity Baseball, Camp Kesem, Travel, Hiking",
      [configKeys.Big]: "Tommy Hannan '2021"
    },
    "Jack Phifer": {
      [configKeys.Hometown]: "Tampa, FL",
      [configKeys.Major]: "6-3",
      [configKeys.Interests]: "Co-Founder of Finary, Poker, Sports Betting",
      [configKeys.Big]: "Joe Vasille '2020",
    },
    "Alex Ellison": {
      [configKeys.Hometown]: "Tomball, TX",
      [configKeys.Major]: "6-14",
      [configKeys.Interests]:
        "Varsity Swimming, Amphibious Achievement, Code for Good, Development Economics, Startups, Spikeball",
      [configKeys.Big]: "Thomas Allison '2020"
    },
    "Nick West": {
      [configKeys.Big]: "Rishi Shah '2020"
    },
  },

  "2021": {
    "Kevin Yue": {
      [configKeys.Big]: "Darian Bhathena '2020",
    },
    "Justen Holl": {
      [configKeys.Big]: "Jameson Kief '2020",
    },
    "Jacob Phillips": {
      [configKeys.Big]: "Nick Charchut '2019",
    },
    "Tommy Hannan": {
      [configKeys.Big]: "Zack Kopstein '2020",
    },
    "Connor Sweeney": {
      [configKeys.Big]: "Jeffrey Li '2020",
    },
    "Mac Gregg": {
      [configKeys.Big]: "Trey Roberts '2020",
    },
    "Emmanuel Ogunde": {
      [configKeys.Big]: "Christien Williams '2020",
    },
    "Bo Hardin": {
      [configKeys.Big]: "Samir Dutta '2019",
    },
    "Nikola Raicevic": {
      [configKeys.Big]: "Thomas Cho '2017",
    },
    "Gabriel De Los Santos": {
      [configKeys.Big]: "Ariel Brito '2020",
    },
  },

  "2020": {
    "Darian Bhathena": {
      [configKeys.Big]: "Tom Dudzik '2019",
    },
    "Jameson Kief": {
      [configKeys.Big]: "Luca Cacopardo '2018",
    },
    "Vick Liu": {
      [configKeys.Big]: "Michael Picchini '2018",
    },
    "Rishi Shah": {
      [configKeys.Big]: "Ryan Prinster '2018",
    },
    "Jack Strachan": {
      [configKeys.Big]: "Aaron Huang '2019",
    },
    "Ariel Brito": {
      [configKeys.Big]: "Matthew Wu '2019",
    },
    "Thomas Allison": {
      [configKeys.Big]: "Patrick Callahan '2019",
    },
    "AJ Iversen": {
      [configKeys.Big]: "Bret Stepanek '2019",
    },
    "Jeffrey Li": {
      [configKeys.Big]: "Tim Zhong '2018",
    },
    "Zack Kopstein": {
      [configKeys.Big]: "Daniel Carballo '2018",
    },
    "Trey Roberts": {
      [configKeys.Big]: "Puneeth Meruva '2019",
    },
    "Christien Williams": {
      [configKeys.Big]: "Mo Eltahir '2019",
    },
    "Joe Vasille": {
      [configKeys.Big]: "Montana Nimerfroh '2019",
    },
  },

  "2019": {
    "Tom Dudzik": {
      [configKeys.Big]: "Sasha Galitsky '2018",
    },
    "Dotun Adegbite": {
      [configKeys.Big]: "Trevor Romero '2018",
    },
    "Kunal Tangri": {
      [configKeys.Big]: "Cooper Sloan '2018",
    },
    "Montana Nimerfroh": {
      [configKeys.Big]: "Sudhanshu Mishra '2018",
    },
    "Aaron Huang": {
      [configKeys.Big]: "Diego Lazares '2018",
    },
    "Chris Larry": {
      [configKeys.Big]: "Taylor Adler '2018",
    },
    "Nick Charchut": {
      [configKeys.Big]: "Max Lancaster '2018"
    },
    "Alan Chen": {
      [configKeys.Big]: "Andy Wang '2018",
    },
    "Patrick Callahan": {
      [configKeys.Big]: "Nick Martin '2018"
    },
    "Puneeth Meruva": {
      [configKeys.Big]: "Sam Huang '2018",
    },
    "Mo Eltahir": {
      [configKeys.Big]: "Zachary Michaud '2018",
    },
    "Samir Dutta": {
      [configKeys.Big]: "Nischal Nadhamuni '2018",
    },
    "Bret Stepanek": {
      [configKeys.Big]: "Andrew Hall '2018",
    },
    "Ji-seok Kim": {
      [configKeys.Big]: "Jin Kim '2018",
    },
    "Bobby Rauch": {
      [configKeys.Big]: "Mitch Turley '2018",
    },
    "Anthony Badea": {
      [configKeys.Big]: "Billy Ruschel '2018",
    },
    "Matthew Wu": {
      [configKeys.Big]: "David Heller '2018",
    },
  },

  "2018": {
    "Sasha Galitsky": {
      [configKeys.Big]: "Grant Gunnison '2016",
    },
    "Luca Cacopardo": {
      [configKeys.Big]: "Adrian Samsel '2016",
    },
    "Michael Picchini": {
      [configKeys.Big]: "Greg Beams '2016",
    },
    "Max Lancaster": {
      [configKeys.Big]: "Jonathon Zuniga '2016",
    },
    "Ryan Prinster": {
      [configKeys.Big]: "Pat Capulong '2016",
    },
    "Sudhanshu Mishra": {
      [configKeys.Big]: "Karan Kashyap '2016",
    },
    "Trevor Romero": {
      [configKeys.Big]: "Lawrence Chan '2016",
    },
    "Diego Lazares": {
      [configKeys.Big]: "Logan Martin '2017",
    },
    "Taylor Adler": {
      [configKeys.Big]: "Logan Martin '2017",
    },
    "David Heller": {
      [configKeys.Big]: "Jacob Tims '2016"
    },
    "Tim Zhong": {
      [configKeys.Big]: "Victor Zhang '2017",
    },
    "Daniel Carballo": {
      [configKeys.Big]: "Santosh Narayan '2016",
    },
    "Sam Huang": {
      [configKeys.Big]: "Carlos Torres '2016",
    },
    "Andy Wang": {
      [configKeys.Big]: "Kamil Mafoud '2016",
    },
    "Zachary Michaud": {
      [configKeys.Big]: "Suyash Fulay '2017",
    },
    "Nick Martin": {
      [configKeys.Big]: "Chadd Kiggins '2016",
    },
    "Cooper Sloan": {
      [configKeys.Big]: "Matt McEachern '2017",
    },
    "Nick Schwartz": {
      [configKeys.Big]: "Francis Castro '2016",
    },
    "Billy Ruschel": {
      [configKeys.Big]: "Dion Low '2016",
    },
    "Tim Lu": {
      [configKeys.Big]: "Tyson Chen '2017",
    },
    "Nischal Nadhamuni": {
      [configKeys.Big]: "Alex Lee '2017",
    },
    "Ethan Stark": {
      [configKeys.Big]: "Donald Little '2016",
    },
    "Andrew Hall": {
      [configKeys.Big]: "Michael Kaba '2016",
    },
    "Jin Kim": {
      [configKeys.Big]: "Jason Leung '2016",
    },
    "Nick O'Connell": {
      [configKeys.Big]: "Kodiak Brush '2017",
    },
    "Logan Ford": {
      [configKeys.Big]: "Christian Vilanilam '2016",
    },
    "Drew Beller": {
      [configKeys.Big]: "Michael Kaba '2016",
    },
    "Mitch Turley": {
      [configKeys.Big]: "Aaron Zalewski '2017",
    },
  },

  "2017": {
    "Matt McEachern": {
      [configKeys.Big]: "Benjie Xie '2016",
    },
    "Logan Martin": {
      [configKeys.Big]: "Lawrence Chan '2016",
    },
    "Victor Zhang": {
      [configKeys.Big]: "Kevin Tu '2016",
    },
    "Suyash Fulay": {
      [configKeys.Big]: "Carlos Torres '2016",
    },
    "Tyson Chen": {
      [configKeys.Big]: "Jonathon Lui '2016",
    },
    "Mario Ho": {
      [configKeys.Big]: "Santosh Narayan '2016",
    },
    "Alex Lee": {
      [configKeys.Big]: "Brian Kong '2016",
    },
    "Joe Lowman": {
      [configKeys.Big]: "Matt Hole '2016",
    },
    "Kodiak Brush": {
      [configKeys.Big]: "Christian Reed '2016",
    },
    "Thomas Cho": {
      [configKeys.Big]: "Rishikesh Tirumala '2016",
    },
    "Aaron Zalewski": {
      [configKeys.Big]: "Jacob Tims '2016",
    },
  },

  "2016": {
    "Grant Gunnison": {
      [configKeys.Big]: "Matt Barron '2013",
    },
    "Adrian Samsel": {
      [configKeys.Big]: "Aron Dreyfoos '2013",
    },
    "Greg Beams": {
      [configKeys.Big]: "Adrian Cole '2013",
    },
    "Benjie Xie": {
      [configKeys.Big]: "Kyle Hannon '2013",
    },
    "Chadd Kiggins": {
      [configKeys.Big]: "Josh Duncavage '2013",
    },
    "Jonathon Zuniga": {
      [configKeys.Big]: "Chris Welch '2013",
    },
    "Pat Capulong": {
      [configKeys.Big]: "Larry Pang '2013",
    },
    "Karan Kashyap": {
      [configKeys.Big]: "Ram Venkateswaran '2013",
    },
    "Lawrence Chan": {
      [configKeys.Big]: "Yida Gao '2013",
    },
    "Kevin Tu": {
      [configKeys.Big]: "Terence Hsu '2013",
    },
    "Carlos Torres": {
      [configKeys.Big]: "Nahum Seifeselassie '2013",
    },
    "Kamil Mafoud": {
      [configKeys.Big]: "Richard Yoon '2013",
    },
    "Jacob Tims": {
      [configKeys.Big]: "Josh Duncavage '2013",
    },

    "Francis Castro": {
      [configKeys.Big]: "DJ Ronde '2013",
    },
    "Dion Low": {
      [configKeys.Big]: "Dhruv Parthasarathy '2013",
    },
    "Jonathon Lui": {
      [configKeys.Big]: "Albert Kao '2012",
    },
    "Santosh Narayan": {
      [configKeys.Big]: "Yida Gao '2013",
    },
    "Ram Baskar": {
      [configKeys.Big]: "Ram Venkateswaran '2013",
    },
    "Brian Kong": {
      [configKeys.Big]: "Terence Hsu '2013",
    },
    "Devin Dee": {
      [configKeys.Big]: "Jacky Lau '2011",
    },
    "Donald Little": {
      [configKeys.Big]: "Richard Wood '2013",
    },
    "Matt Hole": {
      [configKeys.Big]: "Zachary Steward '2013",
    },
    "Michael Kaba": {
      [configKeys.Big]: "Brian Djaja '2013",
    },
    "Jason Leung": {
      [configKeys.Big]: "David Way '2013",
    },
    "Ingwon Chae": {
      [configKeys.Big]: "Chris Welch '2013",
    },
    "Christian Reed": {
      [configKeys.Big]: "Timmy Galvin '2013",
    },
    "Rishikesh Tirumala": {
      [configKeys.Big]: "Thiago Vieria '2013",
    },
    "Christian Vilanilam": {
      [configKeys.Big]: "Nischay Kumar '2012",
    },
  },

  "2015": {},

  "2014": {},

  "2013": {
    "Timmy Galvin": {
      [configKeys.Big]: "Andres Camarena '2010",
    },
    "Josh Duncavage": {
      [configKeys.Big]: "Ashwin Suresh '2012",
    },
    "Matt Barron": {
      [configKeys.Big]: "Nick Leonard '2011",
    },
    "Thiago Vieria": {
      [configKeys.Big]: "Brendon Sullivan '2011",
    },
    "Zachary Steward": {
      [configKeys.Big]: "Daniel Kushkuley '2011",
    },
    "Brian Djaja": {
      [configKeys.Big]: "Jay Rajan '2011",
    },
    "David Way": {
      [configKeys.Big]: "Michael Puncel '2012",
    },
    "Chris Welch": {
      [configKeys.Big]: "George Blake '2011",
    },
    "Richard Yoon": {
      [configKeys.Big]: "Tony Tran '2011",
    },
    "Yida Gao": {
      [configKeys.Big]: "Jeff Zhao '2011",
    },
    "Ram Venkateswaran": {
      [configKeys.Big]: "Ambar Mehta '2012",
    },
    "Terence Hsu": {
      [configKeys.Big]: "Jacky Lau '2011",
    },
    "Kyle Hannon": {
      [configKeys.Big]: "Stephen Ge '2012",
    },
    "Dhruv Parthasarathy": {
      [configKeys.Big]: "Albert Kao '2012",
    },
    "Larry Pang": {
      [configKeys.Big]: "Carter Chang '2012",
    },
    "DJ Ronde": {
      [configKeys.Big]: "Nathan Li '2011",
    },
    "Richard Woo": {
      [configKeys.Big]: "Neil Chao '2010",
    },
    "Aron Dreyfoos": {
      [configKeys.Big]: "Kyle Knoblock '2011",
    },
    "Nahum Seifeselassie": {
      [configKeys.Big]: "Chris Boyce '2011",
    },
    "Adrian Cole": {
      [configKeys.Big]: "David Thompson '2011",
    },
    "Richard Wood": {
      [configKeys.Big]: "Neil Chao '2010",
    },
  },

  "2012": {
    "Ashwin Suresh": {
      [configKeys.Big]: "Nick Leonard '2011",
    },
    "Nischay Kumar": {
      [configKeys.Big]: "Varun Chirravuri '2009",
    },
    "Dhruv Garg": {
      [configKeys.Big]: "Ansul Bhagi '2011",
    },
    "Michael Puncel": {
      [configKeys.Big]: "George Blake '2011",
    },
    "Jay Park": {
      [configKeys.Big]: "Phillip Kim '2010",
    },
    "Jerry Wang": {
      [configKeys.Big]: "Kevin Chou '2011",
    },
    "Ambar Mehta": {
      [configKeys.Big]: "Arjun Naskar '2009",
    },
    "Stephen Ge": {
      [configKeys.Big]: "Jacky Lau '2011",
    },
    "Albert Kao": {
      [configKeys.Big]: "Charlie Huang '2011",
    },
    "Carter Chang": {
      [configKeys.Big]: "Nathan Li '2011",
    },
    "Samvaran Sharma": {
      [configKeys.Big]: "Vinayak Muralidhar '2010",
    },
  },

  "2011": {
    "Nick Leonard": {
      [configKeys.Big]: "Xiao Wei Chen '2009",
    },
    "Brendon Sullivan": {
      [configKeys.Big]: "Jeremy McGee '2009",
    },
    "Ansul Bhagi": {
      [configKeys.Big]: "Varun Chirravuri '2009",
    },
    "Sonny Thai": {
      [configKeys.Big]: "Desh Mohan '2010",
    },
    "Daniel Kushkuley": {
      [configKeys.Big]: "Blane Ziegler '2008",
    },
    "Jay Rajan": {
      [configKeys.Big]: "Rajat Bhalia '2008",
    },
    "George Blake": {
      [configKeys.Big]: "Jeremy Richardson '2009",
    },
    "Tony Tran": {
      [configKeys.Big]: "Phillip Kim '2010",
    },
    "Jeff Zhao": {
      [configKeys.Big]: "Xing Yuan '2008",
    },
    "Kevin Chou": {
      [configKeys.Big]: "Steven Hong '2010",
    },
    "Jason Zhu": {
      [configKeys.Big]: "Michael Oh '2009",
    },
    "Kevin Rustagi": {
      [configKeys.Big]: "Arjun Naskar '2009",
    },
    "Jacky Lau": {
      [configKeys.Big]: "Aaron Cheung '2009",
    },
    "Gary Cao": {
      [configKeys.Big]: "Byron Hsu '2006",
    },
    "Charlie Huang": {
      [configKeys.Big]: "Matt Ng '2008",
    },
    "Nathan Li": {
      [configKeys.Big]: "Steven Camina '2009",
    },
    "Luis Lafer-Sousa": {
      [configKeys.Big]: "Dan Lopez '2009",
    },
    "Adam Doroski": {
      [configKeys.Big]: "Kevin Plumer '2010",
    },
    "Trey Rakotojoana": {
      [configKeys.Big]: "Neil Chao '2010",
    },
    "Kyle Knoblock": {
      [configKeys.Big]: "Lawrence Maligaya '2009",
    },
    "Chris Boyce": {
      [configKeys.Big]: "Brian Greviskes '2008",
    },
    "David Thompson": {
      [configKeys.Big]: "Demario Dayton '2009",
    },
  },

  "2010": {
    "Andres Camarena": {
      [configKeys.Big]: "Shamus Cunningham '2009",
    },
    "Desh Mohan": {
      [configKeys.Big]: "Varun Chirravuri '2009",
    },
    "Josh Mears": {
      [configKeys.Big]: "Randall Briggs '2009",
    },
    "Phillip Kim": {
      [configKeys.Big]: "Bryan Koo '2009",
    },
    "Steven Hong": {
      [configKeys.Big]: "Xing Yuan '2008",
    },
    "Kevin Plumer": {
      [configKeys.Big]: "Patrick Shannon '2009",
    },
    "Neil Chao": {
      [configKeys.Big]: "Gabriel Chan '2009",
    },
    "Vinayak Muralidhar": {
      [configKeys.Big]: "Jugal Shah '2008",
    },
  },

  "2009": {
    "Shamus Cunningham": {
      [configKeys.Big]: "Kurt Tummel '2008",
    },
    "Xiao Wei Chen": {
      [configKeys.Big]: "Sam Hwang '2005",
    },
    "Jeremy McGee": {
      [configKeys.Big]: "Zain Gulamall '2006",
    },
    "Varun Chirravuri": {
      [configKeys.Big]: "Melvin Makhni '2007",
    },
    "Jonathan Frazier": {
      [configKeys.Big]: "Blane Ziegler '2008",
    },
    "Martin Lorilla": {
      [configKeys.Big]: "John Pavlish '2007",
    },
    "Eric Wang": {
      [configKeys.Big]: "Cyrus Weaver '2007",
    },
    "Randall Briggs": {
      [configKeys.Big]: "Eugene Lim '2008",
    },
    "Jeremy Richardson": {
      [configKeys.Big]: "Isaac Wu '2006",
    },
    "Bryan Koo": {
      [configKeys.Big]: "Xing Yuan '2008",
    },
    "Michael Oh": {
      [configKeys.Big]: "Ben Levick '2008",
    },
    "Richard Lin": {
      [configKeys.Big]: "Jason Park '2006",
    },
    "Arjun Naskar": {
      [configKeys.Big]: "Jameel Khalfan '2006",
    },
    "Aaron Cheung": {
      [configKeys.Big]: "Lincoln Pasquina '2007",
    },
    "Reginal Edwards": {
      [configKeys.Big]: "Kevin Cho '2008",
    },
    "Steven Camina": {
      [configKeys.Big]: "Matt Ng '2008",
    },
    "Dan Lopez": {
      [configKeys.Big]: "Chris Mattenberger '2006",
    },
    "Patrick Shannon": {
      [configKeys.Big]: "Allen Reyes '2007",
    },
    "Gabriel Chan": {
      [configKeys.Big]: "Winston Lin '2007",
    },
    "Lawrence Maligaya": {
      [configKeys.Big]: "Simon Shum '2008",
    },
    "Daniel Hung": {
      [configKeys.Big]: "Ben Lee '2007",
    },
    "Chris Hansen": {
      [configKeys.Big]: "Curtis Cook '2006",
    },
    "Demario Dayton": {
      [configKeys.Big]: "Brian Greviskes '2008",
    },
  },

  "2008": {
    "Kurt Tummel": {
      [configKeys.Big]: "Adam Chao '2005",
    },
    "Greg Hobart": {
      [configKeys.Big]: "Jonathan West '2006",
    },
    "Blane Ziegler": {
      [configKeys.Big]: "Alan Anderson '2007",
    },
    "Rajat Bhalia": {
      [configKeys.Big]: "Cyrus Weaver '2007",
    },
    "Eugene Lim": {
      [configKeys.Big]: "Isaac Wu '2006",
    },
    "Xing Yuan": {
      [configKeys.Big]: "Walter Lin '2006",
    },
    "Ben Levick": {
      [configKeys.Big]: "Brandon Chao '2005",
    },
    "Kevin Cho": {
      [configKeys.Big]: "Byron Hsu '2006",
    },
    "Matt Ng": {
      [configKeys.Big]: "Joshwa Wang '2007",
    },
    "Simon Shum": {
      [configKeys.Big]: "Joseph Liao '2006",
    },
    "Brian Greviskes": {
      [configKeys.Big]: "Fahad Kajani '2005",
    },
    "Jugal Shah": {
      [configKeys.Big]: "Mayur Desai '2006",
    },
  },

  "2007": {
    "Melvin Makhni": {
      [configKeys.Big]: "Zain Gulamall '2006",
    },
    "Alan Anderson": {
      [configKeys.Big]: "Dennis Dillon '2005",
    },
    "John Pavlish": {
      [configKeys.Big]: "Robert Tau '2005",
    },
    "Cyrus Weaver": {
      [configKeys.Big]: "Isaac Wu '2006",
    },
    "Lincoln Pasquina": {
      [configKeys.Big]: "Eric Makhni '2005",
    },
    "Joshwa Wang": {
      [configKeys.Big]: "Dewang Shavdia '2006",
    },
    "Charles Hung": {
      [configKeys.Big]: "Leo Wong '2006",
    },
    "Allen Reyes": {
      [configKeys.Big]: "Chris Mattenberger '2006",
    },
    "Winston Lin": {
      [configKeys.Big]: "Owen Haiming Sun '2005",
    },
    "Ben Lee": {
      [configKeys.Big]: "Joseph Liao '2006",
    },
  },

  "2006": {
    "Chris Kim": {
      [configKeys.Big]: "Adam Chao '2005",
    },
    "Zain Gulamall": {
      [configKeys.Big]: "Sam Hwang '2005",
    },
    "Denver Thomas": {
      [configKeys.Big]: "Lavoska Burton '2005",
    },
    "Jonathan West": {
      [configKeys.Big]: "Matt Wilkerson '2004",
    },
    "Rob Reyes": {
      [configKeys.Big]: "Rodrigo Luna '2003",
    },
    "Isaac Wu": {
      [configKeys.Big]: "Robert Tau '2005",
    },
    "Walter Lin": {
      [configKeys.Big]: "Mike Ho '2004",
    },
    "Jason Park": {
      [configKeys.Big]: "Brandon Chao '2005",
    },
    "Jameel Khalfan": {
      [configKeys.Big]: "Rafiq Dhanani '2005",
    },
    "Byron Hsu": {
      [configKeys.Big]: "Jon Lee '2005",
    },
    "Dewang Shavdia": {
      [configKeys.Big]: "Samir Kendale '2005",
    },
    "Leo Wong": {
      [configKeys.Big]: "Tim Chan '2004",
    },
    "Chris Mattenberger": {
      [configKeys.Big]: "Patrick Korb '2005",
    },
    "Joseph Liao": {
      [configKeys.Big]: "Owen Haiming Sun '2005",
    },
    "Curtis Cook": {
      [configKeys.Big]: "Amit Gupta '2005",
    },
    "Krishna Pokala": {
      [configKeys.Big]: "Fahad Kajani '2005",
    },
    "Mayur Desai": {
      [configKeys.Big]: "Neil Sengupta '2003",
    },
  },

  "2005": {
    "Steven Okano": {
      [configKeys.Big]: "Victor Hsu '2004",
    },
    "Adam Chao": {
      [configKeys.Big]: "David Schannon '2004",
    },
    "Sam Hwang": {
      [configKeys.Big]: "Andy Crane '2002",
    },
    "Lavoska Burton": {
      [configKeys.Big]: "Matt Wilkerson '2004",
    },
    "Ben Hebert": {
      [configKeys.Big]: "Joe Yu '2004",
    },
    "Dennis Dillon": {
      [configKeys.Big]: "Larry Hawe '2004",
    },
    "Robert Tau": {
      [configKeys.Big]: "Jerry Ing '2004",
    },
    "Brandon Chao": {
      [configKeys.Big]: "Rodney Huang '2003",
    },
    "Rafiq Dhanani": {
      [configKeys.Big]: "Ryan Manuel '2004",
    },
    "Eric Makhni": {
      [configKeys.Big]: "Ayodele Ijidakinro '2003",
    },
    "Jon Lee": {
      [configKeys.Big]: "Pat Lo '2004",
    },
    "Samir Kendale": {
      [configKeys.Big]: "Tejvinder Parmar '2002",
    },
    "Gordon Fellows": {
      [configKeys.Big]: "Kara Quine '2004",
    },
    "Patrick Korb": {
      [configKeys.Big]: "Kevin Johnson '2002",
    },
    "Matt Tschantz": {
      [configKeys.Big]: "Herman Lelie '2003",
    },
    "Owen Haiming Sun": {
      [configKeys.Big]: "George Lee '2003",
    },
    "Amit Gupta": {
      [configKeys.Big]: "Smith Sirisakorn '2004",
    },
    "Fahad Kajani": {
      [configKeys.Big]: "Neil Sengupta '2003",
    },
  },

  "2004": {
    "Victor Hsu": {
      [configKeys.Big]: "Bob Mellen '2003",
    },
    "David Schannon": {
      [configKeys.Big]: "Andy Crane '2002",
    },
    "Matt Wilkerson": {
      [configKeys.Big]: "Jovan Hsu '2003",
    },
    "Joe Yu": {
      [configKeys.Big]: "Jeremy Johnson '2001",
    },
    "Larry Hawe": {
      [configKeys.Big]: "Marc Moesse '2000",
    },
    "Jerry Ing": {
      [configKeys.Big]: "Vincent Hon '2002",
    },
    "Mike Ho": {
      [configKeys.Big]: "Reggie Rogers '2001",
    },
    "Nick Fung": {
      [configKeys.Big]: "Rodney Huang '2003",
    },
    "Ryan Manuel": {
      [configKeys.Big]: "Jae Ro '2002",
    },
    "Pat Lo": {
      [configKeys.Big]: "Ayodele Ijidakinro '2003",
    },
    "Anand Patel": {
      [configKeys.Big]: "Tejvinder Parmar '2002",
    },
    "Tim Chan": {
      [configKeys.Big]: "Justin McBride '2002",
    },
    "Kara Quine": {
      [configKeys.Big]: "Kevin Johnson '2002",
    },
    "Smith Sirisakorn": {
      [configKeys.Big]: "George Lee '2003",
    },
  },

  "2003": {
    "Bob Mellen": {
      [configKeys.Big]: "Jeremy Johnson '2001"
    },
    "Jovan Hsu": {
      [configKeys.Big]: "Andy Crane '2002",
    },
    "Rodrigo Luna": {
      [configKeys.Big]: "Justin Siou '1999",
    },
    "Rodney Huang": {
      [configKeys.Big]: "Dharmesh Mehta '2000",
    },
    "Ayodele Ijidakinro": {
      [configKeys.Big]: "Jae Ro '2002",
    },
    "Derek Tsu": {
      [configKeys.Big]: "Tejvinder Parmar '2002",
    },
    "Herman Lelie": {
      [configKeys.Big]: "Kevin Johnson '2002",
    },
    "George Lee": {
      [configKeys.Big]: "Amar Mehta '2001",
    },
    "Neil Sengupta": {
      [configKeys.Big]: "Amar Mehta '2001",
    },
  },

  "2002": {
    "Andy Crane": {
      [configKeys.Big]: "Jeremy Johnson '2001",
    },
    "Vincent Hon": {
      [configKeys.Big]: "Long Phi Le '1999",
    },
    "Jae Ro": {
      [configKeys.Big]: "Dharmesh Mehta '2000",
    },
    "Tejvinder Parmar": {
      [configKeys.Big]: "Akash Patel '1999",
    },
    "Justin McBride": {
      [configKeys.Big]: "Ryan Porter '2001",
    },
    "Kevin Johnson": {
      [configKeys.Big]: "Geoffery Williamson '2001",
    },
    "Spencer Seung": {},
    "Alexey Dronov": {
      [configKeys.Big]: "Benson Fu '2000",
    },
  },

  "2001": {
    "Jeremy Johnson": {
      [configKeys.Big]: "Eugene Park '1998",
    },
    "Reggie Rogers": {
      [configKeys.Big]: "Brian McElwain '2000",
    },
    "Paritosh Somani": {
      [configKeys.Big]: "Dharmesh Mehta '2000"
    },
    "Ryan Porter": {
      [configKeys.Big]: "Shane Wu '1999",
    },
    "Geoffery Williamson": {
      [configKeys.Big]: "Be Ware '1999",
    },
    "Amar Mehta": {
      [configKeys.Big]: "Jaspal Sandhu '1999",
    },
    "JaspalSandhu": {
      [configKeys.Big]: "Nate Gleason '1998",
    },
  },

  "2000": {
    "Marc Moesse": {
      [configKeys.Big]: "Justin Siou '1999",
    },
    "Brian McElwain": {
      [configKeys.Big]: "Bryan Poltilove '1997",
    },
    "Dharmesh Mehta": {
      [configKeys.Big]: "Steven Yang '1999",
    },
    "Joshua Kaufman": {
      [configKeys.Big]: "Shane Wu '1999",
    },
    "Jasper Chen": {
      [configKeys.Big]: "Be Ware '1999",
    },
    "Victor Chang": {
      [configKeys.Big]: "Gregory Dante Roulette '1998",
    },
    "Benson Fu": {
      [configKeys.Big]: "Jaspal Sandhu '1999",
    },
  },

  "1999": {
    "Thomas Benjamin Self": {
      [configKeys.Big]: "Robert Marsh '1998",
    },
    "Justin Siou": {
      [configKeys.Big]: "Hyder Husain '1996",
    },
    "Long Phi Le": {
      [configKeys.Big]: "Eugene Lee '1998",
    },
    "Brian Yoo": {
      [configKeys.Big]: "Chris Eng '1998",
    },
    "Steven Yang": { // deaffiliated
      [configKeys.Big]: "Bryan Poltilove '1997",
    },
    "Akash Patel": {
      [configKeys.Big]: "Raj Vazirani '1997",
    },
    "Khalid Shakir": {
      [configKeys.Big]: "Reggie Green '1998",
    },
    "Shane Wu": {},
    "Tony Chao": {
      [configKeys.Big]: "Jason Iafolla '1997",
    },
    "Be Ware": {
      [configKeys.Big]: "Chris Millard '1996",
    },
    "Michael Li": {
      [configKeys.Big]: "Gregory Dante Roulette '1998",
    },
    "Jaspal Sandhu": {
      [configKeys.Big]: "Nate Gleason '1998",
    },

  },

  "1998": {
    "Tony Yuen": {
      [configKeys.Big]: "Eric Lo '1996",
    },
    "Dan Son": {
      [configKeys.Big]: "Jin Park '1996",
    },
    "Eugene Park": {
      [configKeys.Big]: "Brian Davies '1995",
    },
    "Robert Marsh": {
      [configKeys.Big]: "Hyder Husain '1996",
    },
    "Eugene Lee": {
      [configKeys.Big]: "Yedilaklil Workeneh '1997",
    },
    "Chris Eng": {
      [configKeys.Big]: "Gary Gill '1996",
    },
    "Charles Oji": {
      [configKeys.Big]: "Hector Ayala '1995",
    },
    "Geoff Lanyon": {
      [configKeys.Big]: "Nicholas Mason '1995",
    },
    "Dennis Dougherty": {
      [configKeys.Big]: "Anthony Ku '1997",
    },
    "Reggie Green": {},
    "Gregory Dante Roulette": {
      [configKeys.Big]: "Oliver Schneider '1995",
    },
    "Nate Gleason": {
      [configKeys.Big]: "Jeff Hsu '1994",
    },
    "Joseph Kim": {
      [configKeys.Big]: "Peter Lee '1996",
    },
  },

  "1997": {
    "Yedilaklil Workeneh": {
      [configKeys.Big]: "Hyder Husain '1996",
    },
    "Bryan Poltilove": {
      [configKeys.Big]: "Nicholas Mason '1995",
    },
    "Anthony Ku": {
      [configKeys.Big]: "Bill Lin '1994",
    },
    "Raj Vazirani": {
      [configKeys.Big]: "Raj Sodhi '1995",
    },
    "Jason Iafolla": {
      [configKeys.Big]: "Ed Moore '1994",
    },
  },

  "1996": {
    "Eric Lo": {},
    "Jin Park": {},
    "Hyder Husain": {
      [configKeys.Big]: "Brian Davies '1995",
    },
    "Gary Gill": {
      [configKeys.Big]: "Joe Veys '1995",
    },
    "Peter Lee": {
      [configKeys.Big]: "Nicholas Mason '1995",
    },
    "Chris Millard": {
      [configKeys.Big]: "Ed Moore '1994",
    },
  },

  "1995": {
    "Brian Davies": {
      [configKeys.Big]: "Ben Moskowitz '1994",
    },
    "Joe Veys": {
      [configKeys.Big]: "Andrew Luan '1993",
    },
    "Hector Ayala": {
      [configKeys.Big]: "Michael Tso '1993",
    },
    "Nicholas Mason": {
      [configKeys.Big]: "Vasik Rajlich '1993",
    },
    "Raj Sarkar": {
      [configKeys.Big]: "Shin-John Choi '1993",
    },
    "Ben Yu": {
      [configKeys.Big]: "Mike Daly '1994",
    },
    "Raj Sodhi": {
      [configKeys.Big]: "Kofi Amankwah '1994",
    },
    "Oliver Schneider": {
      [configKeys.Big]: "Ed Moore '1994",
    },
    "Sean Pak": {
      [configKeys.Big]: "Jeff Hsu '1994",
    },
    "Roberto Almeida": {
      [configKeys.Big]: "Joshua Koppelman '1994",
    },
  },

  "1994": {
    "Joshua Koppelman": {
      [configKeys.Big]: "Anthony Lee '1991",
    },
    "Dan Friedman": {
      [configKeys.Big]: "Prashun Patel '1993",
    },
    "Sergio Rubio": {
      [configKeys.Big]: "George Chen '1991",
    },
    "Ben Moskowitz": {
      [configKeys.Big]: "Andrew Luan '1993",
    },
    "Steven Bae": {},
    "Roger Flugel": {},
    "Steven Wong": {
      [configKeys.Big]: "Michael Tso '1993",
    },
    "Jeffery Hung": {
      [configKeys.Big]: "Vasik Rajlich '1993",
    },
    "Raul Valdez": {
      [configKeys.Big]: "Shin-John Choi '1993",
    },
    "Mike Daly": {
      [configKeys.Big]: "Sam Leung '1993",
    },
    "Bill Lin": {
      [configKeys.Big]: "Ted Child '1991",
    },
    "Kofi Amankwah": {
      [configKeys.Big]: "Michael Haney '1993",
    },
    "Ed Moore": {
      [configKeys.Big]: "Tim Koning '1992",
    },
    "Jeff Hsu": {
      [configKeys.Big]: "Joe Dimare '1992",
    },
  },

  "1993": {
    "Tim Kukulski": {
      [configKeys.Big]: "Anthony Lee '1991",
    },
    "Prashun Patel": {
      [configKeys.Big]: "Josh Powlesson '1992",
    },
    "Mark Pfohl": {
      [configKeys.Big]: "Rick Paxson '1992",
    },
    "Andrew Luan": {
      [configKeys.Big]: "Richard Ball '1991",
    },
    "Dave Ferguson": {
      [configKeys.Big]: "Sean Kelley '1989",
    },
    "Michael Tso": {
      [configKeys.Big]: "Steven Trost '1991",
    },
    "Vasik Rajlich": {
      [configKeys.Big]: "Jerko Fatovic '1992",
    },
    "Dan Theobald": {
      [configKeys.Big]: "Matt Wall '1987",
    },
    "Shin-John Choi": {
      [configKeys.Big]: "Tain-Yen Hsai '1990",
    },
    "Sam Leung": {
      [configKeys.Big]: "Ted Child '1991",
    },
    "Michael Haney": {
      [configKeys.Big]: "Gil Speyer '1992",
    },
    "Albert Chong": {
      [configKeys.Big]: "Peter Woo '1990",
    },
    "Namuk Cho": {
      [configKeys.Big]: "Eddy Tan '1990",
    },
  },

  "1992": {
    "Josh Powlesson": {
      [configKeys.Big]: "Anthony Lee '1991",
    },
    "Mingyi Tsai": {
      [configKeys.Big]: "George Chen '1991",
    },
    "Bob Amini": {
      [configKeys.Big]: "Kevin Robinson '1990",
    },
    "Rick Paxson": {
      [configKeys.Big]: "Richard Ball '1991",
    },
    "Mike Piepergerdes": {
      [configKeys.Big]: "Steve Codell '1990",
    },
    "Jerko Fatovic": {
      [configKeys.Big]: "Marc Jorrens '1989",
    },
    "Joe Norton": {
      [configKeys.Big]: "Matt Wall '1987",
    },
    "Gil Speyer": {
      [configKeys.Big]: "Rodhill Jack '1990",
    },
    "Tim Koning": {
      [configKeys.Big]: "Peter Gordon '1990",
    },
    "Joe Dimare": {
      [configKeys.Big]: "Peter Woo '1990",
    },
  },

  "1991": {
    "Anthony Lee": {
      [configKeys.Big]: "Scott Furman '1990",
    },
    "George Chen": {
      [configKeys.Big]: "Moshin Lee '1988",
    },
    "Richard Ball": {
      [configKeys.Big]: "Anton Briefer '1988",
    },
    "Ramin Tavassoli": {
      [configKeys.Big]: "Steve Codell '1990",
    },
    "Steven Trost": {
      [configKeys.Big]: "Marc Jorrens '1989",
    },
    "Peter Kohl": {
      [configKeys.Big]: "Huang Do '1988",
    },
    "Furio Ciacci": {
      [configKeys.Big]: "Matt Wall '1987",
    },
    "Ted Child": {
      [configKeys.Big]: "Eddy Tan '1990",
    },
    "Scott Tang": {
      [configKeys.Big]: "Peter Woo '1990",
    },
  },

  "1990": {
    "Scott Furman": {
      [configKeys.Big]: "Moshin Lee '1988",
    },
    "Kevin Robinson": {
      [configKeys.Big]: "Gary Gruberth '1989",
    },
    "Steve Codell": {
      [configKeys.Big]: "Sean Kelley '1989",
    },
    "Senad Prusac": {
      [configKeys.Big]: "Alis Aelion '1989",
    },
    "Tain-Yen Hsai": {
      [configKeys.Big]: "Matt Wall '1987",
    },
    "Eddy Tan": {
      [configKeys.Big]: "Mike Hou '1988",
    },
    "Rodhill Jack": {
      [configKeys.Big]: "Jay Best '1989",
    },
    "Brett VanDerLaan": {
      [configKeys.Big]: "Peter Wong '1989",
    },
    "Peter Gordon": {
      [configKeys.Big]: "Raju Rishi '1988",
    },
    "Lorenzo Levi": {
      [configKeys.Big]: "John Mohr '1986",
    },
    "Brian Hunter": {
      [configKeys.Big]: "Alex Cohen '1987",
    },
    "Sasan Zamani": {
      [configKeys.Big]: "Karim Roshd '1988",
    },
    "Peter Woo": {
      [configKeys.Big]: "Simon Lie '1987",
    },
    "Eric Shank": {
      [configKeys.Big]: "Kris Grube '1987",
    },
    "Keith Carey": {
      [configKeys.Big]: "Hanson Cheah '1988",
    },
  },

  "1989": {
    "Doug Chu": {
      [configKeys.Big]: "Michael Davis '1986",
    },
    "Gary Gruberth": {
      [configKeys.Big]: "Moshin Lee '1988",
    },
    "Sean Kelley": {
      [configKeys.Big]: "Anton Briefer '1988",
    },
    "Alis Aelion": {
      [configKeys.Big]: "Dave Karohl '1985",
    },
    "Marc Jorrens": {
      [configKeys.Big]: "Daniel Harasty '1986",
    },
    "Charles Johnson": {
      [configKeys.Big]: "Daniel Harasty '1986",
    },
    "Craig Giordano": {
      [configKeys.Big]: "Mike Hou '1988",
    },
    "Jay Best": {
      [configKeys.Big]: "Bob Henshaw '1986",
    },
    "Peter Wong": {
      [configKeys.Big]: "Jerry Martin '1986",
    },
    "Nick Newman": {
      [configKeys.Big]: "Simon Lie '1987",
    },
    "Hugo Ayala": {
      [configKeys.Big]: "Kris Grube '1987",
    },
  },

  "1988": {
    "Basil Horangic": {
      [configKeys.Big]: "John Hibel '1986",
    },
    "Moshin Lee": {
      [configKeys.Big]: "Rey Librojo '1987",
    },
    "Anton Briefer": {
      [configKeys.Big]: "Jae Koul Kim '1986",
    },
    "Antoine Firmenich": {
      [configKeys.Big]: "Sam Cable '1985",
    },
    "Huang Do": {
      [configKeys.Big]: "Daniel Harasty '1986",
    },
    "Mike Hou": {
      [configKeys.Big]: "Leland Mah '1983",
    },
    "Marco Maroccia": {
      [configKeys.Big]: "Charles Peckham '1984",
    },
    "Raju Rishi": {
      [configKeys.Big]: "John Mohr '1986",
    },
    "Eric Rizotto": {
      [configKeys.Big]: "Frankie Law '1985",
    },
    "Karim Roshd": {
      [configKeys.Big]: "Jay Elson '1985",
    },
    "Hanson Cheah": {
      [configKeys.Big]: "Kris Grube '1987",
    },
  },

  "1987": {
    "Rey Librojo": {
      [configKeys.Big]: "Jae Koul Kim '1986",
    },
    "Sol Goldfarb": {
      [configKeys.Big]: "Adrian Wang '1985",
    },
    "Michael Dennis": {
      [configKeys.Big]: "Daniel Harasty '1986",
    },
    "Matt Wall": {
      [configKeys.Big]: "Ronald van Veen '1985",
    },
    "Gabe Cole": {
      [configKeys.Big]: "Bruce Kinzinger '1984",
    },
    "Alex Cohen": {
      [configKeys.Big]: "Homero Rey '1986",
    },
    "Simon Lie": {
      [configKeys.Big]: "Sam Crawford '1983",
    },
    "Kris Grube": {
      [configKeys.Big]: "George Fan '1985",
    },
    "Mike Skeldon": {
      [configKeys.Big]: "Tom Eccles '1986",
    },
  },

  "1986": {
    "Michael Davis": {
      [configKeys.Big]: "Randy Pratt '1983",
    },
    "John Hibel": {
      [configKeys.Big]: "Steve Martin '1985",
    },
    "Jae Koul Kim": {
      [configKeys.Big]: "Forrest Thiessen '1984",
    },
    "Daniel Harasty": {
      [configKeys.Big]: "Ronald van Veen '1985",
    },
    "Bob Henshaw": {
      [configKeys.Big]: "Bruce Kinzinger '1984",
    },
    "Jerry Martin": {
      [configKeys.Big]: "Steve Herricks '1984",
    },
    "John Mohr": {
      [configKeys.Big]: "Robert Lezec '1984",
    },
    "Homero Rey": {
      [configKeys.Big]: "Frankie Law '1985",
    },
    "Tom Eccles": {
      [configKeys.Big]: "Ivan Fong '1983",
    },
    "Mikko Hakkarainen": {
      [configKeys.Big]: "Jaime Guillen '1985",
    },
  },

  "1985": {
    "Steve Martin": {
      [configKeys.Big]: "Forrest Thiessen '1984",
    },
    "Adrian Wang": {
      [configKeys.Big]: "Karl Bupp '1984",
    },
    "Dave Karohl": {
      [configKeys.Big]: "Ken Schreibman '1982",
    },
    "Sam Cable": {},
    "Ronald van Veen": {
      // [configKeys.Big]: "Mark Beasman '1975 & Leland Mah '1983",
      [configKeys.Big]: "Leland Mah '1983"
    },
    "Frankie Law": {
      [configKeys.Big]: "Robert Lezec '1984",
    },
    "Jay Elson": {
      [configKeys.Big]: "Mike Shimazu '1982",
    },
    "George Fan": {
      [configKeys.Big]: "Ivan Fong '1983",
    },
    "Jaime Guillen": {
      [configKeys.Big]: "Andrew Tallian '1982",
    },
    "Scott Dudevoir": {
      [configKeys.Big]: "Nick Nocerino '1983",
    },
  },

  "1984": {
    "Forrest Thiessen": {
      [configKeys.Big]: "Kent Hauser '1982",
    },
    "Karl Bupp": {
      [configKeys.Big]: "Phil Brannigan '1981",
    },
    "Tom Loredo": {
      [configKeys.Big]: "Paul Osepa '1982",
    },
    "Bruce Kinzinger": {
      [configKeys.Big]: "Leland Mah '1983",
    },
    "Steve Herricks": {
      [configKeys.Big]: "Phil Litle '1982",
    },
    "Charles Peckham": {},
    "Robert Lezec": {},
  },

  "1983": {
    "Randy Pratt": {
      [configKeys.Big]: "Jim Buckingham '1980",
    },
    "Michael Bellafiore": {
      [configKeys.Big]: "Joel Luna '1981",
    },
    "Leland Mah": {
      [configKeys.Big]: "Henri Lezec '1981",
    },
    "Tom Over": {
      [configKeys.Big]: "Andrew Washburn '1982",
    },
    "Jesse Castilo": {
      [configKeys.Big]: "Vikram Kirloskar '1981",
    },
    "Steven Kohler": {},
    "Dale Buralli": {
      [configKeys.Big]: "Mike Shimazu '1982",
    },
    "Sam Crawford": {
      [configKeys.Big]: "Alex Mollen '1981",
    },
    "Ivan Fong": {
      [configKeys.Big]: "Andrew Tallian '1982",
    },
    "Nick Nocerino": {
      [configKeys.Big]: "Dave Buffo '1981",
    },
  },

  "1982": {
    "Kent Hauser": {
      [configKeys.Big]: "Stanley Sasaki '1981",
    },
    "David Ostrom": {
      [configKeys.Big]: "Hal Peterson '1980",
    },
    "Ken Schreibman": {
      [configKeys.Big]: "Steve Hunger '1981",
    },
    "Paul Osepa": {
      [configKeys.Big]: "Pete Tierney '1980",
    },
    "Roger Durst": {
      [configKeys.Big]: "Vic Rhoads '1979",
    },
    "Andrew Washburn": {
      [configKeys.Big]: "Andrew Lee '1980",
    },
    "Phil Litle": {
      [configKeys.Big]: "Jim Aslanis '1981",
    },
    "Reggie Chen": {
      [configKeys.Big]: "Binno Louwerenburg '1981",
    },
    "Mike Shimazu": {
      [configKeys.Big]: "Terence Chen '1980",
    },
    "Andrew Tallian": {
      [configKeys.Big]: "Steve Krasniak '1981",
    },
  },

  "1981": {
    "Stanley Sasaki": {},
    "Phil Brannigan": {
      [configKeys.Big]: "Roger Gettel '1979",
    },
    "Steve Hunger": {
      [configKeys.Big]: "Craig Freedlund '1980",
    },
    "Dave Buffo": {
      [configKeys.Big]: "Kevin Haggerty '1977",
    },
    "Joel Luna": {},
    "Henri Lezec": {
      [configKeys.Big]: "Peter Riemenschneider '1980",
    },
    "Jim Aslanis": {
      [configKeys.Big]: "Andrew Lee '1980",
    },
    "Vikram Kirloskar": {
      [configKeys.Big]: "Stephen McNamara '1980",
    },
    "Binno Louwerenburg": {
      [configKeys.Big]: "Syed Zaeem Hosain '1978",
    },
    "Alex Mollen": {
      [configKeys.Big]: "Terence Chen '1980",
    },
    "Steve Krasniak": {},
  },

  "1980": {
    "Jim Buckingham": {},
    "Hal Peterson": {},
    "Craig Freedlund": {
      [configKeys.Big]: "Robbie Currier '1979",
    },
    "Pete Tierney": {},
    "Peter Riemenschneider": {
      [configKeys.Big]: "Tom Hanschen '1977",
    },
    "Andrew Lee": {
      [configKeys.Big]: "Michael Haggerty '1977",
    },
    "Stephen McNamara": {
      [configKeys.Big]: "Theodore Poe '1978",
    },
    "Phil Stark": {
      [configKeys.Big]: "Marc Maderazzo '1976",
    },
    "Terence Chen": {
      [configKeys.Big]: "Doug Morrow '1979",
    },
  },

  "1979": {
    "Roger Gettel": {},
    "Robbie Currier": {
      [configKeys.Big]: "Steve Wertheim '1978",
    },
    "Scott Holmes": {
      [configKeys.Big]: "Bob Steininger '1976",
    },
    "Vic Rhoads": {
      [configKeys.Big]: "Tom Hanschen '1977",
    },
    "Rich Gray": {
      [configKeys.Big]: "Marc Maderazzo '1976",
    },
    "Roy Russell": {
      [configKeys.Big]: "Robert Bo Fried '1976",
    },
    "Doug Morrow": {
      [configKeys.Big]: "Jaun Ocampo '1976",
    },
    "George Blossom": {
      [configKeys.Big]: "Stephen Schmid '1977",
    },
  },

  "1978": {
    "Kendall Jensen": {
      [configKeys.Big]: "Mark Sneeringer '1976",
    },
    "Steve Wertheim": {
      [configKeys.Big]: "Peter Hart '1977",
    },
    "Tom Warner": {
      [configKeys.Big]: "Mark Beasman '1975",
    },
    "Carl Krasniak": {
      [configKeys.Big]: "David Eckel '1976",
    },
    "Theodore Poe": {
      [configKeys.Big]: "David Batchelor '1977",
    },
    "Larry Bloomenkranz": {
      [configKeys.Big]: "Marc Maderazzo '1976",
    },
    "Syed Zaeem Hosain": {
      [configKeys.Big]: "Robert Bo Fried '1976",
    },
    "Jeff Kohler": {
      [configKeys.Big]: "Gong Ping Yeh '1975",
    },
    "Bob Jacobsen": {
      [configKeys.Big]: "Scott Moor '1977",
    },
    "Bill Rittman": {
      [configKeys.Big]: "Stephen Schmid '1977",
    },
  },

  "1977": {
    "Marty Cawthon": {
      [configKeys.Big]: "John Pettersen '1975",
    },
    "Peter Hart": {},
    "Kevin Haggerty": {
      [configKeys.Big]: "Bob Steininger '1976",
    },
    "Kerry Emanuel": {
      [configKeys.Big]: "Mark Beasman '1975",
    },
    "Rick Bauer": {
      [configKeys.Big]: "Paul Ahrens '1974",
    },
    "Tom Hanschen": {
      [configKeys.Big]: "David Eckel '1976",
    },
    "Michael Haggerty": {
      [configKeys.Big]: "Paul Lemaire '1975",
    },
    "David Batchelor": {
      [configKeys.Big]: "Ronald Molony '1974",
    },
    "Roger Bamford": {
      [configKeys.Big]: "Marc Maderazzo '1976",
    },
    "Alan Joslin": {
      [configKeys.Big]: "Gong Ping Yeh '1975",
    },
    "Jon Warren": {
      [configKeys.Big]: "Gong Ping Yeh '1975",
    },
    "Scott Moor": {},
    "Stephen Schmid": {
      [configKeys.Big]: "Gary Goehrke '1976",
    },
  },

  "1976": {
    "Mark Sneeringer": {
      [configKeys.Big]: "Cliff Podewell '1974",
    },
    "Steven Jones": {
      [configKeys.Big]: "Gray Safford '1975",
    },
    "Ron Salomon": {
      [configKeys.Big]: "John Yates '1975",
    },
    "Bob Steininger": {
      [configKeys.Big]: "Mark Beasman '1975",
    },
    "David Eckel": {
      [configKeys.Big]: "Tom Jahns '1973",
    },
    "Marc Maderazzo": {
      [configKeys.Big]: "Rich Kane '1975",
    },
    "Robert Bo Fried": {
      [configKeys.Big]: "Gong Ping Yeh '1975",
    },
    "Jaun Ocampo": {
      [configKeys.Big]: "Ken Luey '1975",
    },
    "Gary Goehrke": {
      [configKeys.Big]: "Mike Arnold '1973",
    },
  },

  "1975": {
    "John Pettersen": {},
    "Gray Safford": {
      [configKeys.Big]: "Roger King '1973",
    },
    "John Yates": {},
    "Steven Walters": {
      [configKeys.Big]: "Anthony Sun '1974",
    },
    "Mark Beasman": {
      [configKeys.Big]: "Gary Lake '1972",
    },
    "Tom McKim": {
      [configKeys.Big]: "Bob Lambe '1974",
    },
    "Paul Lemaire": {
      [configKeys.Big]: "Paul Wagoner '1974",
    },
    "Rich Kane": {
      [configKeys.Big]: "Ronald Molony '1974",
    },
    "Gong Ping Yeh": {},
    "Ken Luey": {
      [configKeys.Big]: "Gary Ezzell '1973",
    },
    "George Lechter": {
      [configKeys.Big]: "Mike Arnold '1973",
    },
    "Albert Chin": {
      [configKeys.Big]: "Scott Berg '1973",
    },
  },

  "1974": {
    "Cliff Podewell": {},
    "Anthony Sun": {},
    "Paul Ahrens": {
      [configKeys.Big]: "Tom Jahns '1973",
    },
    "Bob Lambe": {
      [configKeys.Big]: "Scott Berg '1973",
    },
    "Paul Wagoner": {
      [configKeys.Big]: "Fred Zerhoot '1973",
    },
    "Ronald Molony": {},
  },

  "1973": {
    "Roger King": {},
    "Tom Jahns": {},
    "Scott Berg": {
      [configKeys.Big]: "Raymond Azzi '1972",
    },
    "Fred Zerhoot": {
      [configKeys.Big]: "Greg Bernhardt '1971",
    },
    "Gary Ezzell": {},
    "Mike Arnold": {},
  },

  "1972": {
    "Bob Mayer": {
      [configKeys.Big]: "Peter Cooper '1970",
    },
    "John Mangold": {
      [configKeys.Big]: "Reid Ashe '1970",
    },
    "Gary Lake": {
      [configKeys.Big]: "Mike Neschleba '1969",
    },
    "Raymond Azzi": {
      [configKeys.Big]: "Greg Bernhardt '1971",
    },
  },

  "1971": {
    "Greg Bernhardt": {
      [configKeys.Big]: "Joseph Kubit '1970",
    },
  },

  "1970": {
    "Peter Cooper": {},
    "Steve Carhart": {
      [configKeys.Big]: "Chris Brooks '1969",
    },
    "Reid Ashe": {},
    "Joseph Kubit": {},
  },

  "1969": {
    "Chris Brooks": {},
    "Mike Neschleba": {},
  },
};
