import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/Footer.module.css';
import triangle_png from '../assets/triangle-228x123.png';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const Footer = () => {
    return (
        <Container fluid className={styles.footerContainer}>
            <Row md={6} className={styles.footerRow}>

                {/* Brother's Only */}
                <Col className={`${styles.footerCol} d-flex align-items-center justify-content-start`}>
                    <a href='/brothersOnly' className={`${styles.colTitleLink}`}>BROTHERS ONLY</a>
                </Col>

                {/* PBE Triangle logo */}
                <Col className={`${styles.footerCol} d-flex align-items-center justify-content-center`}>
                    <img src={triangle_png} style={{height: '96px'}}/>
                </Col>

                {/* Socials */}
                <Col md={2} className={`d-flex align-items-center justify-content-end ${styles.footerCol}`}>
                    <a href="https://www.instagram.com/phibetaepsilon/" target='_blank' className={styles.socialIcon}>
                        <BsInstagram />
                    </a>
                    <a href="https://www.facebook.com/PhiBetaEpsilon/" target='_blank' className={styles.socialIcon}>
                        <FaFacebookF />
                    </a>
                </Col>

            </Row>

            <hr className={styles.horizontalLine} />

            {/* Address */}
            <Row md={8} className={`justify-content-center text-center ${styles.footerCol} ${styles.addressText}`} >
                <Col md={6} className={styles.footerCol}>
                <p className={styles.colText}>400 Memorial Dr, Cambridge, MA 02139</p>
                </Col>
            </Row>

            {/* Copyright info */}
            <Row md={8} className={`justify-content-center text-center ${styles.footerCol}`} >
                <Col md={6} className={styles.footerCol}>
                    <p className={styles.copyrightText}>© Copyright {new Date().getFullYear()} Phi Beta Epsilon - All Rights Reserved</p>
                </Col>
            </Row>
        
        </Container>
    )
}

export default Footer;