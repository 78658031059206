// @ts-nocheck

import React, { useRef, useEffect } from "react";
import * as d3 from 'd3';
import { TREEDATA } from "./infoGeneration/data";
import { NUMBEROFBROS, LONGESTFAMLINES } from "./infoGeneration/stats";
import { select } from "d3";
import './assets/treeStyles.css';
import pbeCrest from './assets/pbeCrest.png';
import { configKeys, officers } from "../../config";

//data
const data = TREEDATA;

//colors
const evenDarkerPeacockBlue = "#23b187";
const evenDarkerPeacockBlueRGB = 'rgb(35, 177, 135)';
const peacockBlue = "#a8f4dd";
const peacockBlueRGB = 'rgb(168, 244, 221)';
const lighterPeacockBlue = "#d4f6eb";

//chart component
const FamilyTree = () => {

  document.title = 'PBE Family Tree';
  //refs
  const itemsRef = useRef([]);
  const pushRef = (el) => itemsRef.current.push(el)

  //draws chart
  useEffect(() => {
    // size of the diagram
    const viewerWidth = window.innerWidth;
    const viewerHeight = window.innerHeight;

    const svg = select(itemsRef.current[3]);
    const searchInput = select(itemsRef.current[0]);
    // const searchButton = select(itemsRef.current[1]);
    const collapseAllButton = select(itemsRef.current[2]);
    const expandAllButton = select(itemsRef.current[1]);
    const resultsBox = document.getElementById("resultsBox");
    resultsBox.style.maxHeight = '50vh';

    // Specify the charts’ dimensions. The height is variable, depending on the layout.
    const chartWidth = Math.max(1928, viewerWidth);
    const marginTop = 10;
    const marginRight = 10;
    const marginBottom = 10;
    const marginLeft = 100;

    const defaultZoom = 1.75;
    const svgHeight = '92vh';

    // Rows are separated by dx pixels, columns by dy pixels. These names can be counter-intuitive
    // (dx is a height, and dy a width). This because the tree must be viewed with the root at the
    // “bottom”, in the data domain. The width of a column is based on the tree’s height.
    const root = d3.hierarchy(data);
    const dx = 11;
    const dy = 2.5 * (chartWidth - marginRight - marginLeft) / (1 + root.height);

    // Define the tree layout and the shape for links.
    const tree = d3.tree().nodeSize([dx, dy]);
    const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x);

    const zoomListener = d3.zoom()
      .extent([[0, 0], [chartWidth, chartWidth]])
      .scaleExtent([0.3, 8])
      .on("zoom", zoom)

    // Create the SVG container, a layer for the links and a layer for the nodes.
    svg
      .attr("width", chartWidth)
      .attr("height", dx)
      .attr("viewBox", [-marginLeft, -marginTop, chartWidth, dx])
      .attr("style", `max-width: 100%; font: 9px sf-reg; user-select: none; fill: black`)
      .call(zoomListener);

    const gLink = svg.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.25)
      .attr("stroke-width", 1.2);

    const gNode = svg.append("g")
      .attr("cursor", "pointer")
      .attr("pointer-events", "all")

    //Zoom in/out to the default zoom value
    svg.call(zoomListener.transform, d3.zoomIdentity.scale(defaultZoom));;

    // Define the zoom function for the zoomable tree
    function zoom({ transform }) {
      gNode.attr("transform", transform);
      gLink.attr("transform", transform);
    }

    function update(event, source) {
      const duration = event?.altKey ? 2500 : 250; // hold the alt key to slow down the transition
      const nodes = root.descendants().reverse();
      const links = root.links();

      // Compute the new tree layout.
      tree(root);

      let left = root;
      let right = root;
      root.eachBefore(node => {
        if (node.x < left.x) left = node;
        if (node.x > right.x) right = node;
      });

      const height = right.x - left.x + marginTop + marginBottom;

      const transition = svg.transition()
        .duration(duration)
      // Commented out to prevent the tree from zooming out when expand all is clicked
      // .attr("height", height)
      // .attr("viewBox", [-marginLeft, left.x - marginTop, chartWidth, height])
      // .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"));

      // Update the nodes…
      const node = gNode.selectAll("g")
        .data(nodes, d => d.id);

      // Enter any new nodes at the parent's previous position.
      const nodeEnter = node.enter().append("g")
        .attr("transform", d => `translate(${source.y0},${source.x0})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0)
        .on("click", (event, d) => {
          if (d !== root) {
            if (d.children) collapse(d);
            else expand(d, true);
            update(event, d);
          }
        });

      nodeEnter.append("circle")
        .attr("r", 3.5)
        .attr("fill", d => d._children ? evenDarkerPeacockBlue : lighterPeacockBlue)
        .attr("stroke-width", 10);

      nodeEnter.append("svg:image")
        .attr("href", (d) => d === root ? pbeCrest : undefined)
        .attr("x", (d) => d === root ? "-21px" : "0")
        .attr("y", (d) => d === root ? "-25px" : "0")
        .attr("width", (d) => d === root ? "50px" : "0")
        .attr("height", (d) => d === root ? "50px" : "0");

      nodeEnter.append("text")
        .attr("dy", "0.31em")
        .attr("x", d => d._children ? -6 : 6)
        .attr("text-anchor", d => d._children ? "end" : "start")
        .text(d => d.data.name)
        .attr("stroke-linejoin", "round")
        .attr("stroke-width", 3)
        .attr("stroke", "white")
        .attr("paint-order", "stroke");

      // Transition nodes to their new position.
      const nodeUpdate = node.merge(nodeEnter).transition(transition)
        .attr("transform", d => `translate(${d.y},${d.x})`)
        .attr("fill-opacity", 1)
        .attr("stroke-opacity", 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node.exit().transition(transition).remove()
        .attr("transform", d => `translate(${source.y},${source.x})`)
        .attr("fill-opacity", 0)
        .attr("stroke-opacity", 0);

      // Update the links…
      const link = gLink.selectAll("path")
        .data(links, d => d.target.id);

      // Enter any new links at the parent's previous position.
      const linkEnter = link.enter().append("path")
        .attr("d", d => {
          const o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        });

      // Transition links to their new position.
      link.merge(linkEnter).transition(transition)
        .attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link.exit().transition(transition).remove()
        .attr("d", d => {
          const o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        });

      // Stash the old positions for transition.
      root.eachBefore(d => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // Do the first update to the initial configuration of the tree — where a number of nodes
    // are open (arbitrarily selected as the root, plus nodes with 7 letters).
    root.x0 = dy / 2;
    root.y0 = 0;
    root.descendants().forEach((d, i) => {
      d.id = i;
      d._children = d.children;
      if (d.depth) d.children = null;
    });

    update(null, root);

    function centerNode(source, fromSearch?) {
      // let t = d3.zoomTransform(svg.node());
      let x;
      let y;
      if (!fromSearch) {
        x = -source.y0 * defaultZoom;
      }
      else {// fromSearch
        x = -source.y0 * defaultZoom + chartWidth / 2;
      }
      y = -source.x0 * defaultZoom;
      svg
        .transition()
        .duration(250)
        .call(zoomListener.transform, d3.zoomIdentity.translate(x, y).scale(defaultZoom));
    }

    // Helper functions for collapsing and expanding nodes.
    function collapse(d) {
      if (d.children) {
        d._children = d.children;
        for (const c of d._children) {
          collapse(c)
        }
        d.children = null;
      }
    }

    function collapseAll() {
      if (root._children) {
        for (const c of root._children) {
          collapse(c);
        }
      }
      collapse(root);
      root.children = root._children;
      root._children = null;
      update(null, root);
      centerNode(root);
    }

    function expand(d, singleNode?= false) {
      const children = (d.children) ? d.children : d._children;
      if (d._children) {
        d.children = d._children;
        // d._children = null;
      }
      if (children && !singleNode) {
        for (const c of children) {
          expand(c);
        }
      }
    }

    function expandAll() {
      expand(root);
      update(null, root);
      centerNode(root);
    }

    collapseAllButton.on("click", collapseAll);
    expandAllButton.on("click", expandAll);

    function findMatchingBros(query) {
      const bros = [];
      const dfs = (bro) => {
        if (bro.data?.name?.toLowerCase().indexOf(query) !== -1) {
          bros.push(bro.data.name);
        }
        if (bro.children || bro._children) {
          // bro has children
          const children = (bro.children) ? bro.children : bro._children;
          for (const child of children) {
            dfs(child);
          }
        }
      }
      dfs(root);
      return bros;
    }

    function findPathToBro(name: string, path: Array<any>) {
      const lastNode = path[path.length - 1];
      if (lastNode?.data?.name === name) return path;

      if (lastNode.children || lastNode._children) {
        // lastNode has children
        const children = (lastNode.children) ? lastNode.children : lastNode._children;
        for (const child of children) {
          path.push(child);
          const result = findPathToBro(name, path);
          if (result) return result;
          else path.pop();
        }
      }
      else return false;
    }

    function resetResultsBox() {
      resultsBox.innerHTML = "";
      resultsBox.style.border = "";
      resultsBox.style.borderRadius = "";
    }

    function finishSearch(name: string) {
      document.getElementById("searchInput").value = "";
      const path = findPathToBro(name, [root]);
      collapseAll();
      for (let i = 0; i < path.length - 1 /*want to fully expand the search result node itself, hence length - 1*/; i++) {
        expand(path[i], true);
      }
      expand(path[path.length - 1]);
      update(null, root);
      centerNode(path[path.length - 1], true);
    }

    function search() {
      const query = document.getElementById("searchInput").value.toLowerCase();

      resetResultsBox();

      if (query) {

        const results = findMatchingBros(query);

        if (results.length === 0) {

        }
        // else if (results.length === 1) {
        //   finishSearch(results[0]);
        // }
        else { //Many results
          results.sort((a, b) => {
            const aYear = parseInt(a.slice(a.length - 5, a.length - 1));
            const bYear = parseInt(b.slice(b.length - 5, b.length - 1));
            return bYear - aYear; // Sort from oldest to most recent class year
          });

          for (const name of results) {
            const option = document.createElement("div");
            const optionHeight = '40px';

            const innerText = document.createElement("span");
            innerText.style.color = evenDarkerPeacockBlue;
            innerText.style.transition = '0.15s ease';
            innerText.style.lineHeight = optionHeight;
            innerText.innerHTML = name;

            option.appendChild(innerText);
            option.style.width = '100%';
            option.style.height = optionHeight;
            option.style.paddingLeft = '3%';
            option.style.backgroundColor = 'white';
            option.onmouseover = () => {
              innerText.style.color = peacockBlue;
            }
            option.onmouseleave = () => {
              innerText.style.color = evenDarkerPeacockBlue;
            }

            option.onclick = () => {
              resetResultsBox();
              finishSearch(name);
            }

            resultsBox.appendChild(option);
          }
          resultsBox.style.border = `2px solid ${evenDarkerPeacockBlue}`;
          resultsBox.style.borderRadius = '5px';
        }
      }
    }

    resultsBox.onmouseenter = () => { //Reset all of the option colors
      const options = resultsBox.children;
      for (let i = 0; i < options.length; i++) {
        const innerText = options[i].firstChild;
        innerText.style.color = evenDarkerPeacockBlue;
      }
    }

    // searchButton.on("click", search);
    // searchInput.on("keyup", (e) => {if(e.key === 'Enter') search();});
    searchInput.on("input", search);

    function getSelectedOptionIndex(options) {
      if (!options) return;
      for (let i = 0; i < options.length; i++) {
        const innerText = options[i].firstChild;
        if (innerText.style.color === peacockBlueRGB || innerText.style.color === peacockBlue) {
          return i;
        }
      }
      return -1;
    }

    document.onkeydown = (e) => {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        const options = resultsBox.children;
        if (!options) return;
        const selectedIndex = getSelectedOptionIndex(options);

        if (e.key === "ArrowDown") {
          const nextSelectedIndex = Math.min(selectedIndex + 1, options.length - 1);
          if (selectedIndex !== -1) {
            const selectedText = options[selectedIndex].firstChild;
            selectedText.style.color = evenDarkerPeacockBlue;
          }
          const nextOption = options[nextSelectedIndex];
          const nextSelectedText = nextOption.firstChild;
          nextSelectedText.style.color = peacockBlue;

          const resultsHeightPx = parseFloat(resultsBox.style.maxHeight) * window.innerHeight / 100;
          const optionHeightPx = parseFloat(nextOption.style.height);// * window.innerHeight/100; //uncomment this if the height of nextoption is vh instead of px
          console.log(resultsBox.style.maxHeight)
          console.log(nextOption.style.height);
          resultsBox.scrollTop = Math.max((nextOption.offsetTop - resultsHeightPx + 1.2 * optionHeightPx), resultsBox.scrollTop);
        }

        if (e.key === "ArrowUp") {
          if (selectedIndex !== -1) {
            const nextSelectedIndex = Math.max(selectedIndex - 1, 0);

            const selectedText = options[selectedIndex].firstChild;
            selectedText.style.color = evenDarkerPeacockBlue;

            const nextOption = options[nextSelectedIndex];
            const nextSelectedText = nextOption.firstChild;
            nextSelectedText.style.color = peacockBlue;

            resultsBox.scrollTop = Math.min(nextOption.offsetTop, resultsBox.scrollTop);
          }
        }

      }
    }

    document.onkeyup = (e) => {
      if (e.key === "Enter") {
        const options = resultsBox.children;
        if (!options) return;
        const selectedIndex = getSelectedOptionIndex(options);
        if (selectedIndex !== -1 && selectedIndex !== undefined) {
          const name = options[selectedIndex].firstChild.innerHTML;
          resetResultsBox();
          finishSearch(name);
        }
      }
    }

    document.addEventListener("click", (e) => {
      if (!resultsBox.contains(e.target) && /*!document.getElementById("searchButton").contains(e.target)*/ !document.getElementById("searchInput").contains(e.target)) resetResultsBox();
      if (document.getElementById("searchInput").contains(e.target)) search()
    })

  }, [data]);

  return (

    <div id="treeWrapper">
      <div id="controls">

        <div className="controlssection">
          <input type="text" ref={pushRef} id="searchInput" className="textinp" placeholder="Search by Name or Year" />
          <div id="resultsBox"></div>
        </div>

        <div className="controlssection">
          <button ref={pushRef} id="expandBtn" className="expcolbtn">Expand All</button>
          <button ref={pushRef} id="collapseBtn" className="expcolbtn">Collapse All</button>
        </div>
        {/* <span id="pbeTitle"><b>ΦΒΕ Home</b></span> */}

        <div className="controlssection">
          <div className="dropdown" id="helpdrop">
            <button className="dropbtn">Help</button>
            <div className="dropdown-content">
              <span className="category">Click on a dark peacock blue node to expand or collapse it.</span>
              <span className="category">Click and drag to pan, and pinch or scroll to zoom.</span>
              <span className="category">Search for brother x to expand the direct path to x, as well as all of x's descendents.</span>
              <span className="category">Email the current [N], {officers[configKeys.N][configKeys.Name]}, {officers[configKeys.N][configKeys.Kerberos]}@mit.edu, if you notice any inaccuracies and/or bugs.</span>
              <span className="category" style={{ fontSize: "70%" }}><i>Developed by [N] Jamison O'Keefe (2026) in Spring 2024, with contributions from Max Lancaster (2018), Aron Dreyfoos (2013), and Nick Anewalt (2023).</i></span>
            </div>
          </div>

          <div className="dropdown" id="statsdrop">
            <button className="dropbtn">Stats</button>
            <div className="dropdown-content">

              <span className="category"><i># of Brothers in the Tree:</i></span>
              <span className="subcat">{NUMBEROFBROS}</span>

              <span className="category" id="longestFamlines"><i>Longest Famline(s):</i></span>
              {
                LONGESTFAMLINES.map((entry) => {
                  const [people, length] = entry;
                  return <span className="subcat" key={people}>{people}</span> // The key property is added because React wants each element generated this way to have a unique key (it serves no real purpose)
                })
              }
              <span className="subcat">{LONGESTFAMLINES[0][1]} brothers long</span>

              <span className="category"><i>Lowest IQ Brother:</i></span>
              <span className="subcat">Drew Geoly (2026)</span>
              <span className="subcat">(sub 1460 SAT score)</span>

              <span className="category"><i>Fun Fact:</i></span>
              <span className="subcat">PBE has Steven Hong (2010), Stephen Hong (2024), Jameson Kief (2020), and Jamison O'Keefe (2026).</span>

            </div>
          </div>
        </div>

      </div>

      <svg ref={pushRef} id="treeSvg"></svg>
    </div>
  );
};

export default FamilyTree;