import {useEffect} from 'react';
import { GiRank3 } from 'react-icons/gi';

// Components
import TextButtonImageSection from '../components/TextButtonImageSection';
import BrotherCardsSection from '../components/BrothersPage/BrotherCardsSection';
import OfficersTable from '../components/BrothersPage/OfficersTable';

// Images
import bigLittleMagianosClass20_jpeg from '../assets/BrothersPage/dsc00873-2-2000x1333.jpeg';
import ringDeliveryBois_jpg from '../assets/BrothersPage/ring_delivery_dark.jpg';
import ringpremierebois_jpeg from '../assets/BrothersPage/RingPremiereBois.jpeg'; //2026 Ring Premiere Photo. They're all cheering for the the coolest phi bete, Jamison O'Keefe, who's premiering the shit outta that ring.

const BrothersPage = () => {
    useEffect(() => {
        document.title = 'Brothers'
     }, []);

    return (
        <div style={{overflow: 'hidden'}}>
            <TextButtonImageSection config={{
                imgSrc: ringDeliveryBois_jpg,
                minHeight: '60vh',
                imgConfig: {tintColor: '#353631', tintOpacity: 0.3, attachment: 'fixed', positionY: '35%'},
                headerText: 'BROTHERS',
                buttonText: 'OFFICERS',
                buttonLink: "/brothers#officers",
                buttonIcon: <GiRank3/>
            }}/>
            <BrotherCardsSection />
            <OfficersTable />
        </div>
    )
}

export default BrothersPage;